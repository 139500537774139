import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular/standalone';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { ModalsService } from '@services/modals.service';
import { UserModel } from '@shared/models/user.model';
import { ActiveUserService } from '@shared/services/active-user.service';
import { DeviceService } from '@shared/services/device.service';
import * as userActions from '@store/actions/user';
import * as notificationsSelectors from '@store/selectors/notifications.selectors';
import { addIcons } from 'ionicons';
import {
  chatbubbles,
  hammer,
  notifications,
  settings,
  shareSocial,
  tv,
} from 'ionicons/icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  activeUser: UserModel;
  mobile: boolean;
  private _unsubscribeAll: Subject<void> = new Subject();

  unread$ = this.store.select((state) =>
    notificationsSelectors.selectUnread(state)
  );

  constructor(
    private activeUserService: ActiveUserService,
    private store: Store<IState>,
    private modalsService: ModalsService,
    private menuCtrl: MenuController,
    public platform: Platform,
    private breakpointService: DeviceService,
    private router: Router
  ) {
    this.breakpointService.isMobile$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isMobile) => (this.mobile = isMobile));
    addIcons({ tv, settings, hammer, notifications, shareSocial, chatbubbles });
  }

  ngOnInit() {
    this.activeUser = this.activeUserService.user();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openSearch() {
    this.modalsService.addFromSearch();
  }

  isUserAuthenticated() {
    return this.activeUser && this.activeUser.Id;
  }

  isKiosker() {
    return this.activeUser && this.activeUser.IsKiosker;
  }

  logout() {
    this.menuCtrl.close();
    this.store.dispatch(userActions.LogoutRequest());
  }

  takePicture() {
    this.modalsService.addFromCamera();
  }

  navigateToUserProfile() {
    this.router.navigate(['tabs', 'social', 'users', this.activeUser.Id]);
  }

  rateApp() {
    if (this.platform.is('android')) {
      // we do not want to open this in app but use the default browser
      window.open(
        'https://play.google.com/store/apps/details?id=com.ionicframework.cigarscanner238202&hl=en',
        '_blank',
        'location=yes'
      );
    }
  }
}
