import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BarcodeFormat as NativeBarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { QuaggaJSCodeReader } from '@ericblade/quagga2';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { BarcodeScannerLivestreamModule } from './barcode-scanner-livestream/barcode-scanner-livestream.module';
import { ScannerNativeFallbackModal } from './native-fallback-modal.component';
import { ScannerService } from './scanner.service';
import { ScannerConfigToken } from './token';
import { ScannerWebModalComponent } from './web-modal.component';

export interface ScannerConfig {
  proposeToInstallGoogleNativeScanner: boolean;
  webFormats: QuaggaJSCodeReader[];
  nativeFormats: NativeBarcodeFormat[];
  nativeFallbackActiveClass: string;
  nativeFallbackClass: string;
}

@NgModule({
  imports: [
    CommonModule,
    // ZXingScannerModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonItem,
    IonLabel,
    BarcodeScannerLivestreamModule,
  ],
  declarations: [ScannerWebModalComponent, ScannerNativeFallbackModal],
})
export class ScannerModule {
  static forRoot(config: ScannerConfig): ModuleWithProviders<ScannerModule> {
    return {
      ngModule: ScannerModule,
      providers: [
        { provide: ScannerConfigToken, useValue: config },
        ScannerService,
      ],
    };
  }
}
