<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Microstore</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-segment
      [value]="selectedTab"
      (ionChange)="segmentButtonClicked($event.detail.value)"
    >
      <ion-segment-button value="pending-orders">
        <ion-label>Pending Orders</ion-label>
      </ion-segment-button>
      <ion-segment-button value="past-orders">
        <ion-label>Past Orders</ion-label>
      </ion-segment-button>
      <ion-segment-button value="settings">
        <ion-label>Settings</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="page-container">
    <router-outlet></router-outlet>
  </div>
</ion-content>
