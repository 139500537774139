import { RxLet } from '@rx-angular/template/let';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Platform, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/angular/standalone';
import { ModalsService } from '@services/modals.service';
import { DeviceService } from '@shared/services/device.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addIcons } from "ionicons";
import { shareSocial, camera, hammer } from "ionicons/icons";

@Component({
    standalone: true,
    imports: [CommonModule, RxLet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel],
    templateUrl: 'tabs-page.html',
    styleUrls: ['tabs-page.scss'],
})
export class TabsPage implements OnDestroy {
    isMobile: boolean;
    private _unsubscribeAll: Subject<void> = new Subject();

    constructor(
        private modalsService: ModalsService,
        private breakpointService: DeviceService,
        public platform: Platform
    ) {
        this.breakpointService.isMobile$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isMobile) => (this.isMobile = isMobile));
        addIcons({ shareSocial, camera, hammer });
    }

    async addFromCamera() {
        this.modalsService.addFromCamera();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
