import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalService } from '@services/modal.service';
import { AppRoutes } from '@utils/routes';
import { IonContent, IonButton, IonFooter } from "@ionic/angular/standalone";

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, IonContent, IonButton, IonFooter],
    template: `
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>Keep you data safe!</ion-title>
      </ion-toolbar>
    </ion-header> -->

    <ion-content class="ion-padding">
      <h2>You are currently using Cigar Scanner as a guest</h2>
      <h4>Register now for Free and enjoy the following features:</h4>
      <ul>
        <li>Access your account from any device by just logging in!</li>
        <li>Don't take the risk of loosing your history! Your data are safe</li>
        <li>
          Participate in our social feeds to learn more about Premium Cigars
        </li>
        <li>
          Transfer your purchases from our retail partner
          <a (click)="openNeptune()">NeptuneCigar.com</a> to your virtual
          humidor
        </li>
      </ul>
      <div class="flex">
        <ion-button
          class="flex-1"
          color="light"
          expand="block"
          (click)="dismiss()"
        >
          Not Now
        </ion-button>
        <ion-button
          class="flex-1"
          expand="block"
          [routerLink]="routes.register()"
          (click)="dismiss()"
        >
          Register
        </ion-button>
      </div>
    </ion-content>

    <ion-footer> </ion-footer>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
      ion-textarea {
        width: 100%;
      }
      :host ion-footer ::ng-deep span {
        pointer-events: none;
      }
    `,
    ],
})
export class GuestRegistrationModal implements OnInit {
    routes = AppRoutes;

    constructor(private modalService: ModalService) { }

    ngOnInit() { }

    dismiss() {
        this.modalService.dismiss(null, 'buttons');
    }

    openNeptune() {
        Browser.open({ url: 'https://www.neptunecigar.com' });
    }
}
