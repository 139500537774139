import { Injectable } from '@angular/core';
import { IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as myHumidorsActions from '@store/actions/humidors';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

interface Data {
  HumidorId: string;
  OfflineSince: string;
}
@Injectable()
export class SensorOfflineSocketEventHandler
  implements SocketEventHandlerInterface
{
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<Data>) {
    // Ignore the message if HumidorId is not defined
    if (!socketMessage.Data.HumidorId || !socketMessage.Data.OfflineSince) {
      return;
    }

    this.store.dispatch(
      myHumidorsActions.HumidorSocketOfflineUpdate({
        humidor: {
          Id: socketMessage.Data.HumidorId,
          SensorOfflineSince: socketMessage.Data.OfflineSince,
        },
      })
    );
  }

  deleted() {}

  updated() {}
}
