import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'createSource' })
export class CreateSourcePipe implements PipeTransform {
  transform(link: string, fallback = 'assets/images/user-image.png'): string {
    if (!link) return fallback;
    if (link && !link.startsWith('https:')) {
      link = `https:${link}`;
    }
    link = link.replace(
      'neptunecigarcontent.blob.core.windows.net',
      'az571366.vo.msecnd.net'
    );
    return link;
  }
}
