import { NgModule } from '@angular/core';

import { CreateSourcePipe } from './create-source.pipe';
import { NumeralPipe } from './numeral.pipe';
import { PriceRangePipe } from './price-range.pipe';
import { UserNamePipe } from './user-name.pipe';

@NgModule({
  imports: [NumeralPipe, UserNamePipe],
  declarations: [CreateSourcePipe, PriceRangePipe],
  exports: [CreateSourcePipe, PriceRangePipe, UserNamePipe, NumeralPipe],
  providers: [],
})
export class SharedPipesModule {}
