import { Injectable } from '@angular/core';

import { KnowledgeModel } from '../../models/knowledge.model';
import { SocketMessageModel } from '../../models/socket-message.model';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class KnowledgeSocketEventHandler
  implements SocketEventHandlerInterface
{
  constructor() {}

  created(socketMessage: SocketMessageModel) {
    const knowledgeItem = new KnowledgeModel(socketMessage.Data);

    // Ignore the message if ContentId is not defined
    if (!knowledgeItem.ContentId) {
      return;
    }

    // NO NEED TO IMPLEMENT THIS
  }

  deleted(socketMessage: SocketMessageModel) {
    // NO NEED TO IMPLEMENT THIS
  }

  updated(socketMessage: SocketMessageModel) {
    const knowledgeItem = new KnowledgeModel(socketMessage.Data);

    // Ignore the message if ContentId is not defined
    if (!knowledgeItem.ContentId) {
      return;
    }

    // NO NEED TO IMPLEMENT THIS
  }
}
