<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{ pageTitle }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="page-container custom-cigar-wrapper">
    <ion-card class="no-style-mobile">
      <ion-card-content>
        <ion-list lines="full">
          <form
            *ngIf="cigarData"
            #form="ngForm"
            (ngSubmit)="submit(form.valid)"
            novalidate
          >
            <ion-item>
              <ion-label position="floating">Cigar Name</ion-label>
              <ion-input
                type="text"
                name="name"
                #name="ngModel"
                required
                [(ngModel)]="cigarData.Name"
              ></ion-input>
              <ion-note
                color="danger"
                class="form-errors"
                *ngIf="!name.valid && (name.touched || form.submitted)"
              >
                <p *ngIf="name.errors?.required">Field is required</p>
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Description</ion-label>
              <ion-textarea
                type="text"
                name="text"
                rows="8"
                #text="ngModel"
                [(ngModel)]="cigarData.Description"
                required
              ></ion-textarea>
              <ion-note
                color="danger"
                class="form-errors"
                *ngIf="!text.valid && (text.touched || form.submitted)"
              >
                <p *ngIf="text.errors?.required">Field is required</p>
              </ion-note>
            </ion-item>

            <ion-item>
              <ion-label>Manufacturer</ion-label>
              <ion-select
                [(ngModel)]="cigarData.ManufacturerValueId"
                name="manufacturer"
              >
                <ion-select-option
                  *ngFor="let manufacturer of manufacturers"
                  [value]="manufacturer.Id"
                  >{{ manufacturer.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label>Strength</ion-label>
              <ion-select
                [(ngModel)]="cigarData.StrengthValueId"
                name="strength"
              >
                <ion-select-option
                  *ngFor="let strength of strengths"
                  [value]="strength.Id"
                  >{{ strength.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label>Origin</ion-label>
              <ion-select [(ngModel)]="cigarData.OriginValueId" name="origin">
                <ion-select-option
                  *ngFor="let origin of origins"
                  [value]="origin.Id"
                  >{{ origin.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label>Wrapper</ion-label>
              <ion-select [(ngModel)]="cigarData.WrapperValueId" name="wrapper">
                <ion-select-option
                  *ngFor="let wrapper of wrappers"
                  [value]="wrapper.Id"
                  >{{ wrapper.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label>Binder</ion-label>
              <ion-select [(ngModel)]="cigarData.BinderValueId" name="binder">
                <ion-select-option
                  *ngFor="let binder of binders"
                  [value]="binder.Id"
                  >{{ binder.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label>Filler</ion-label>
              <ion-select [(ngModel)]="cigarData.FillerValueId" name="filler">
                <ion-select-option
                  *ngFor="let filler of fillers"
                  [value]="filler.Id"
                  >{{ filler.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label>Wrapper Color</ion-label>
              <ion-select
                [(ngModel)]="cigarData.WrapperColorValueId"
                name="wrapperColor"
              >
                <ion-select-option
                  *ngFor="let wrapperColor of wrapperColors"
                  [value]="wrapperColor.Id"
                  >{{ wrapperColor.Value }}</ion-select-option
                >
              </ion-select>
            </ion-item>
            <!-- <ion-item *ngIf="!cigar && !humidor">
              <ion-label>List type</ion-label>
              <ion-select class="list" [(ngModel)]="list" name="list">
                <ion-select-option
                  *ngFor="let list of lists"
                  [value]="list.id"
                  >{{ list.name }}</ion-select-option
                >
              </ion-select>
            </ion-item> -->
            <ion-button
              expand="block"
              size="large"
              color="primary"
              type="submit"
              [disabled]="isLoading"
            >
              {{ textButton }}
            </ion-button>
          </form>
        </ion-list></ion-card-content
      >
    </ion-card>
  </div>
</ion-content>
