import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { KioskCartModel } from '@shared/models/kiosk-cart.model';
import { MicrostoreResource } from '@shared/resources/microstore-resource';

@Component({
    selector: 'app-pending-orders',
    templateUrl: './pending-orders.html',
    styleUrls: ['./pending-orders.scss'],
})
export class PendingOrdersPage implements OnInit {
    pageTitle: string;
    orders: KioskCartModel[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private microstoreResource: MicrostoreResource
    ) { }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data: any) => {
            this.pageTitle = data.title;
            this.orders = data.orders;
        });
    }

    doRefresh(e) {
        this.microstoreResource
            .getCarts(this.microstoreResource.getActiveKiosk().Id, false)
            .subscribe((orders: KioskCartModel[]) => {
                this.orders = orders;
                e.target.complete();
            });
    }
}
