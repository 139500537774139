import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import _get from 'lodash-es/get';

import { HttpErrorResponse } from '@angular/common/http';
import {
  ActionsUnion,
  BannersErrorAction,
  BannersRequestAction,
  BannersSuccessAction,
} from '../reducers/banners';
import { BannersService } from '@services/banners.service';
import { getErrorMessage } from '@utils/errors';

// const mock = [
//   {
//     BannerId: 1,
//     StartDate: '2020-11-16T13:13:53.473',
//     EndDate: '2020-11-30T00:00:00',
//     ImageUrl: 'https://az571366.vo.msecnd.net/res/BlackFriday2020CS.jpg',
//     Text: 'current Banner',
//     RedirectUrl: 'neptunecigar.com',
//     Frequencies: {
//       Overall: 10,
//       Social: 10,
//       Humidor: 12,
//       Journal: 3,
//       Favorites: null,
//       Wishlist: 5,
//     },
//   },
//   {
//     BannerId: 2,
//     StartDate: '2019-11-16T13:13:53.473',
//     EndDate: null,
//     ImageUrl: 'https://via.placeholder.com/300x200',
//     Text: 'whole year Banner 1 ',
//     RedirectUrl: 'neptunecigar.com',
//     Frequencies: {
//       Overall: 10,
//       Social: 10,
//       Humidor: 12,
//       Journal: 5,
//       Favorites: null,
//       Wishlist: 5,
//     },
//   },
//   {
//     BannerId: 3,
//     StartDate: '2020-11-25T13:13:53.473',
//     EndDate: '2020-11-30T00:00:00',
//     ImageUrl: 'https://az571366.vo.msecnd.net/res/BlackFriday2020CS.jpg',
//     Text: 'Future Banner 3',
//     RedirectUrl: 'neptunecigar.com',
//     Frequencies: {
//       Overall: 10,
//       Social: 10,
//       Humidor: 12,
//       Journal: 20,
//       Favorites: null,
//       Wishlist: 5,
//     },
//   },
//   {
//     BannerId: 4,
//     StartDate: '2019-11-25T13:13:53.473',
//     EndDate: '2021-11-30T00:00:00',
//     ImageUrl: 'https://az571366.vo.msecnd.net/res/BlackFriday2020CS.jpg',
//     Text: 'hidden on Journal',
//     RedirectUrl: 'neptunecigar.com',
//     Frequencies: {
//       Overall: 10,
//       Social: 10,
//       Humidor: 12,
//       Journal: null,
//       Favorites: 10,
//       Wishlist: 5,
//     },
//   },
//   {
//     BannerId: 5,
//     StartDate: '2019-11-16T13:13:53.473',
//     EndDate: null,
//     ImageUrl: 'https://via.placeholder.com/200x300',
//     Text: 'whole year Banner 2 ',
//     RedirectUrl: 'neptunecigar.com',
//     Frequencies: {
//       Overall: 10,
//       Social: 10,
//       Humidor: 12,
//       Journal: 20,
//       Favorites: null,
//       Wishlist: 5,
//     },
//   },
// ];

@Injectable()
export class BannersEffects {
  constructor(
    private readonly actions$: Actions<ActionsUnion>,
    private bannerService: BannersService
  ) {}

  requestAll$ = createEffect(() =>  this.actions$.pipe(
    ofType(BannersRequestAction),
    mergeMap(() =>
      this.bannerService.getAll().pipe(
        map((response) =>
          BannersSuccessAction({
            banners: response.Banners,
            frequency: response.Frequency,
          })
        ),
        catchError((er: HttpErrorResponse) =>
          of(BannersErrorAction({ error: getErrorMessage(er) }))
        )
      )
    )
  ));
}
