import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { ActiveUserService } from '../services/active-user.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard  {
  constructor(
    private router: Router,
    private activeUserService: ActiveUserService,
  ) {}

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    // skip if it has redirect_uri for Alexa
    if (
      this.activeUserService.isAuthenticated() &&
      !routeSnapshot.queryParams.redirect_uri
    ) {
      this.router.navigate(['tabs', 'my-cigars']);
    }

    return true;
  }
}
