import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IState } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CigarLogService } from '@services/cigar-log.service';
import { ToastService } from '@services/toast.service';
import * as myCigarsActions from '@store/actions/my-cigars';
import { isCigarLogHumidor, isListHumidor } from '@utils/cigarlogs';
import { getErrorMessage } from '@utils/errors';
import { AppRoutes } from '@utils/routes';
import { combineLatest, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  selectFilters,
  selectMyCigarsListLength,
} from '../selectors/my-cigars.selectors';

@Injectable()
export class MyCigarsEffects {
  constructor(
    private readonly actions$: Actions<myCigarsActions.ActionsUnion>,
    private store: Store<IState>,
    private cigarLogService: CigarLogService,
    private toastService: ToastService,
    private router: Router
  ) {}

  filtersUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsFiltersUpdateAction),
      map(({ listType, filters }) => {
        localStorage.setItem(
          `logs:filters:${listType}`,
          JSON.stringify(filters)
        );
        return myCigarsActions.MyCigarsRequestAction({
          listType,
          reset: true,
        });
      })
    )
  );

  onFiltersClearSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsFiltersClearSuccess),
      map(({ listType }) =>
        myCigarsActions.MyCigarsRequestAction({
          listType,
          reset: true,
        })
      )
    )
  );

  filtersClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsFiltersClearAction),
      map(({ listType }) => {
        localStorage.removeItem(`logs:filters:${listType}`);
        return myCigarsActions.MyCigarsFiltersClearSuccess({
          listType,
        });
      })
    )
  );

  cigarCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsCreateSuccessAction),
      // mergeMap((action) =>
      //   combineLatest([
      //     of(action),
      //     this.store.select((state) =>
      //       selectMyCigarsListLength(state, action.listType)
      //     ),
      //   ]).pipe(first())
      // ),
      // filter(([{ listType }, currentlength]) => currentlength > 0),
      map(({ listType }) =>
        myCigarsActions.MyCigarsRequestAction({
          listType,
          reset: true,
        })
      )
    )
  );

  requestAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsRequestAction),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select((state) => selectFilters(state, action.listType)),
          this.store.select((state) =>
            selectMyCigarsListLength(state, action.listType)
          ),
        ]).pipe(first())
      ),
      switchMap(([{ listType, reset }, currentFilters, currentlength]) => {
        const skip = reset ? 0 : currentlength;
        return this.cigarLogService
          .getConnectedUserList(listType, currentFilters, skip)
          .pipe(
            map((response) =>
              myCigarsActions.MyCigarsSuccessAction({
                listType,
                cigarLogs: response.body,
                skip,
                total: Number(response.headers.get('X-Total-Count') ?? '0'),
              })
            ),
            catchError((er: HttpErrorResponse) =>
              of(
                myCigarsActions.MyCigarsErrorAction({
                  listType,
                  error: getErrorMessage(er),
                })
              )
            )
          );
      })
    )
  );

  getOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsGetOneRequestAction),
      switchMap(({ cigarLogId, listType }) => {
        return this.cigarLogService.getOne(cigarLogId).pipe(
          map((cigarLog) => {
            return myCigarsActions.MyCigarsGetOneSuccessAction({
              cigarLog,
              listType: cigarLog.List,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              myCigarsActions.MyCigarsErrorAction({
                listType,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );

  updateQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsQuantityUpdateRequest),
      mergeMap(({ cigarLog, quantity }) => {
        const isHumidor = isCigarLogHumidor(cigarLog);
        if (!isHumidor) {
          return of(null);
        }
        return this.cigarLogService.updateQuantity(cigarLog.Id, quantity).pipe(
          map((updatedCigarLog) => {
            this.toastService.show('Quantity updated');
            return myCigarsActions.MyCigarsQuantityUpdateSuccess({
              cigarLog: updatedCigarLog,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              myCigarsActions.MyCigarsQuantityUpdateError({
                cigarLog,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );

  updatePrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsPriceUpdateRequest),
      switchMap(({ cigarLog, price }) => {
        return this.cigarLogService.updatePrice(cigarLog.Id, price).pipe(
          map((response) => {
            this.toastService.show('Price updated');
            return myCigarsActions.MyCigarsPriceUpdateSuccess({
              cigarLog: response,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              myCigarsActions.MyCigarsPriceUpdateError({
                cigarLog,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );

  updateOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsUpdateRequestAction),
      switchMap(({ cigarLog }) => {
        return this.cigarLogService.update(cigarLog).pipe(
          map((response) => {
            this.toastService.show('Cigar updated');
            return myCigarsActions.MyCigarsUpdateSuccessAction({
              cigarLog: response,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              myCigarsActions.MyCigarsErrorAction({
                listType: cigarLog.List,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );

  deleteOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsDeleteRequestAction),
      switchMap(({ cigarLog }) => {
        const isHumidor = isCigarLogHumidor(cigarLog);

        return this.cigarLogService.delete(cigarLog.Id).pipe(
          map(() => {
            this.toastService.show('Cigar removed');
            this.router.navigateByUrl(
              isHumidor
                ? AppRoutes.myHumidor(cigarLog.List)
                : AppRoutes.myCigars()
            );

            return myCigarsActions.MyCigarsDeleteSuccessAction({
              cigarLog,
              listType: cigarLog.List,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              myCigarsActions.MyCigarsErrorAction({
                listType: cigarLog.List,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );

  createOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myCigarsActions.MyCigarsCreateRequestAction),
      switchMap((action) => {
        const {
          listType,
          ProductId,
          LineId,
          cigarLogId,
          UserImageUrl,
          RecognitionId,
          Location,
        } = action;
        // copy a cigarlog to a humidor works with cigarlog endpoint
        return (
          cigarLogId
            ? this.cigarLogService.copy(cigarLogId, listType)
            : ProductId
            ? this.cigarLogService.createLogFromProductId(ProductId, listType, {
                UserImageUrl,
                RecognitionId,
                Location,
              })
            : this.cigarLogService.createLogFromLineId(LineId, listType, {
                UserImageUrl,
                RecognitionId,
                Location,
              })
        ).pipe(
          map((response) => {
            this.toastService.show(
              `Cigar added to ${isListHumidor(listType) ? 'humidor' : listType}`
            );
            return myCigarsActions.MyCigarsCreateSuccessAction({
              listType,
              cigarLog: response,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              myCigarsActions.MyCigarsErrorAction({
                listType,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );
}
