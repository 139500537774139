<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/social"></ion-back-button>
    </ion-buttons>
    <ion-title>My profile</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="settings-wrapper">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">My profile</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="page-container my-profile-wrapper">
    <div [hidden]="!showCropper" class="crop-wrapper">
      <img-cropper
        #cropper
        [image]="data"
        [settings]="cropperSettings"
      ></img-cropper>
      <div class="buttons-wrapper">
        <ion-button color="light" size="large" (click)="cancelAvatarChange()">
          <ion-icon slot="icon-only" name="close"></ion-icon
        ></ion-button>
        <ion-button color="success" size="large" (click)="submitImage()"
          ><ion-icon slot="icon-only" name="checkmark"></ion-icon
        ></ion-button>
      </div>
    </div>
    <img
      *ngIf="userData.AvatarUrl"
      [src]="userData.AvatarUrl | createSource"
      class="user-photo"
    />
    <img
      *ngIf="!userData.AvatarUrl"
      src="assets/images/user-image.png"
      class="user-photo"
    />
    <div class="btn-wrapper">
      <a (click)="takePicture()">Change Photo</a>
      <a class="remove-image" *ngIf="userData.AvatarUrl" (click)="removeImage()"
        >Delete photo</a
      >
      <ion-note
        color="danger"
        *ngIf="!lastName.valid && (lastName.touched || form.submitted)"
      >
        <p *ngIf="lastName.errors?.required">Field is required</p>
      </ion-note>
    </div>

    <form #form="ngForm" (ngSubmit)="submit(form)" novalidate>
      <ion-list lines="full">
        <ion-item>
          <ion-label position="floating">First Name *</ion-label>
          <ion-input
            type="text"
            name="first-name"
            [(ngModel)]="userData.FirstName"
            #firstName="ngModel"
            required
          ></ion-input>
          <ion-note
            color="danger"
            class="form-errors"
            *ngIf="!firstName.valid && (firstName.touched || form.submitted)"
          >
            <p *ngIf="firstName.errors?.required">Field is required</p>
          </ion-note>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Last Name *</ion-label>
          <ion-input
            type="text"
            name="last-name"
            [(ngModel)]="userData.LastName"
            #lastName="ngModel"
            required
          ></ion-input>
          <ion-note
            color="danger"
            class="form-errors"
            *ngIf="!lastName.valid && (lastName.touched || form.submitted)"
          >
            <p *ngIf="lastName.errors?.required">Field is required</p>
          </ion-note>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Nickname</ion-label>
          <ion-input
            autocorrect="on"
            autocomplete="on"
            type="text"
            name="nick-name"
            [(ngModel)]="userData.Nickname"
            #nickName="ngModel"
          ></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Email *</ion-label>
          <ion-input
            type="email"
            name="email"
            [(ngModel)]="userData.Email"
            #email="ngModel"
            required
            email
          ></ion-input>
          <ion-note
            color="danger"
            class="form-errors"
            *ngIf="!email.valid && (email.touched || form.submitted)"
          >
            <p *ngIf="email.errors?.required">Field is required</p>
            <p *ngIf="email.errors?.email">Invalid Email</p>
          </ion-note>
        </ion-item>

        <ion-button
          *ngIf="!changePassword"
          class="ion-margin-top"
          expand="block"
          color="primary"
          fill="clear"
          (click)="changePassword = true"
        >
          Change Password
        </ion-button>

        <div *ngIf="changePassword">
          <ion-item>
            <ion-label position="floating">Original Password *</ion-label>
            <ion-input
              type="password"
              name="password"
              [(ngModel)]="userData.Password"
              #password="ngModel"
              required
            ></ion-input>
            <ion-note
              color="danger"
              class="form-errors"
              *ngIf="!password.valid && (password.touched || form.submitted)"
            >
              <p *ngIf="password.errors?.required">Field is required</p>
              <p *ngIf="password.errors?.minlength">Minimum 6 characters</p>
            </ion-note>
          </ion-item>

          <ion-item>
            <ion-label position="floating">New Password *</ion-label>
            <ion-input
              type="password"
              name="new-password"
              [(ngModel)]="userData.NewPassword"
              #newPassword="ngModel"
              required
              [minlength]="6"
            ></ion-input>
            <ion-note
              color="danger"
              class="form-errors"
              *ngIf="
                !newPassword.valid && (newPassword.touched || form.submitted)
              "
            >
              <p *ngIf="newPassword.errors?.required">Field is required</p>
              <p *ngIf="newPassword.errors?.minlength">Minimum 6 characters</p>
            </ion-note>
          </ion-item>

          <ion-item>
            <ion-label position="floating">Confirm New Password *</ion-label>
            <ion-input
              type="password"
              name="new-password-confirmation"
              [(ngModel)]="userData.NewPasswordConfirmation"
              #newPasswordConfirmation="ngModel"
              required
              [minlength]="6"
            ></ion-input>
            <ion-note
              color="danger"
              class="form-errors"
              *ngIf="
                (!newPasswordConfirmation.valid ||
                  userData.NewPassword != userData.NewPasswordConfirmation) &&
                (newPasswordConfirmation.touched || form.submitted)
              "
            >
              <p *ngIf="newPasswordConfirmation.errors?.required">
                Field is required
              </p>
              <p
                *ngIf="
                  userData.NewPassword != userData.NewPasswordConfirmation &&
                  !newPasswordConfirmation.errors?.required
                "
              >
                Passwords don't match
              </p>
            </ion-note>
          </ion-item>
        </div>
      </ion-list>
      <ion-button
        class="ion-margin-top"
        expand="full"
        size="large"
        color="primary"
        type="submit"
      >
        Update My Profile
      </ion-button>
    </form>
  </div>
</ion-content>
