import { NgModule } from '@angular/core';
import { BannersComponent } from '@shared/components/banner/banners.component';
import { CigarBrandLogoComponent } from '@shared/components/cigar-brand-logo/cigar-brand-logo.component';
import { CigarNeptunePriceComponent } from '@shared/components/cigar-neptune-price/cigar-neptune-price.component';
import { CigarPriceComponent } from '@shared/components/cigar-price/cigar-price.component';
import { RatingStarsComponent } from '@shared/components/rating-stars/rating-stars.component';
import { SocialPostComponent } from '@shared/components/social-post/social-post.component';
import { SortButtonsComponent } from '@shared/components/sort-button/sort-buttons.component';
import { UserItemComponent } from '@shared/components/user-item.component';

@NgModule({
  imports: [
    CigarBrandLogoComponent,
    CigarPriceComponent,
    RatingStarsComponent,
    SocialPostComponent,
    UserItemComponent,
    SortButtonsComponent,
    BannersComponent,
    CigarNeptunePriceComponent,
  ],
  declarations: [],
  exports: [
    CigarBrandLogoComponent,
    CigarPriceComponent,
    CigarNeptunePriceComponent,
    SocialPostComponent,
    UserItemComponent,
    BannersComponent,
    SortButtonsComponent,
    BannersComponent,
  ],
  providers: [],
})
export class SharedComponentsModule {}
