import { Injectable } from '@angular/core';
import { KioskUserModel } from '../models/kiosk-user.model';
import { KioskModel } from '../models/kiosk.model';

import { MicrostoreApiResource } from './api/microstore-api.resource';

@Injectable({
  providedIn: 'root'
})
export class MicrostoreResource {
  constructor(
    private api: MicrostoreApiResource
  ) { }

  getKiosks() {
    return this.api.getKiosks();
  }

  getActiveKiosk() {
    return this.api.getActiveKiosk();
  }

  setActiveKiosk(id: number) {
    return  this.api.setActiveKiosk(id);
  }

  getOrder(id: string) {
    return this.api.getOrder(id);
  }

  getPastOrder(id: string) {
    return this.api.getPastOrder(id);
  }

  updateKioskSettings(kiosk: KioskModel) {
    return this.api.updateKioskSettings(kiosk);
  }

  updateUser(user: KioskUserModel) {
    return this.api.updateUser(user);
  }

  deleteUser(user: KioskUserModel) {
    return this.api.deleteUser(user);
  }

  getCarts(id: number, showLoader = true) {
    return this.api.getCarts(id, showLoader);
  }

  approveOrder(cardId: number) {
    return this.api.approveOrder(cardId);
  }

  getPastOrders(id: number) {
    return this.api.getPastOrders(id);
  }

  getPlaces() {
    return this.api.getPlaces();
  }
}
