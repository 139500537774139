import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { addIcons } from "ionicons";
import { arrowUpOutline, arrowDownOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/angular/standalone";

@Component({
    selector: 'cs-sort-buttons',
    standalone: true,
    imports: [CommonModule, IonButton, IonIcon],
    template: `
    <ion-button
      *ngFor="let item of list"
      [ngClass]="{ active: item.name === sortName }"
      (click)="doChange(item.name)"
      fill="clear"
      color="dark"
      >{{ item.title }}

      <ion-icon
        *ngIf="item.name === sortName && sortOrder === 'asc'"
        name="arrow-up-outline"
      ></ion-icon>
      <ion-icon
        *ngIf="item.name === sortName && sortOrder === 'desc'"
        name="arrow-down-outline"
      ></ion-icon>
    </ion-button>
  `,
    styleUrls: ['sort-buttons.scss'],
})
export class SortButtonsComponent {
    @Input() list: {
        name: string;
        title: string;
    }[];
    @Input() sortName: string;
    @Input() sortOrder: 'desc' | 'asc' | undefined;
    @Output() changed = new EventEmitter<string>();

    constructor() {
        addIcons({ arrowUpOutline, arrowDownOutline });
    }

    doChange(name: string) {
        if (name === this.sortName) {
            switch (this.sortOrder) {
                case 'asc':
                    this.changed.emit(`${name}:desc`);
                    break;
                case 'desc':
                    this.changed.emit(`${name}:asc`);
                    break;
            }
        } else {
            this.changed.emit(`${name}:asc`);
        }
    }
}
