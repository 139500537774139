export enum ISocketChannelEnum {
  GLOBAL = 'Global',
  SELF = 'Self',
  PERSONAL = 'Personal',
}

export interface ISocketMessage {
  Id: number;
  UserId: string;
  UUID: string;
  Channel: ISocketChannelEnum;
  Entity: 'SocialPost' | 'CigarRating' | 'HumidorInfo' | 'Humidor';
  Action: 'Created' | 'Updated' | 'Deleted';
  Data: string;
  CreatedOnUTC: string;
  Source?: any;
}

export interface ISocketParsedMessage<T> {
  Id: number;
  UserId: string;
  UUID: string;
  Channel: ISocketChannelEnum;
  Entity: string;
  Action: string;
  Data: T;
  CreatedOnUTC: string;
  Source?: any;
}
