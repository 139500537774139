import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IState } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationsService } from '@services/notifications.service';
import * as notificationsActions from '@store/actions/notifications';
import * as selectorsSelectors from '@store/selectors/notifications.selectors';
import { combineLatest, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';

@Injectable()
export class NotificationsEffects {
  constructor(
    private readonly actions$: Actions<notificationsActions.ActionsUnion>,
    private notificationsService: NotificationsService,
    private store: Store<IState>
  ) {}

  requestAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.NotificationsRequest),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select((state) => selectorsSelectors.selectLength(state)),
        ]).pipe(first())
      ),
      switchMap(([{ reset }, currentlength]) => {
        const skip = reset ? 0 : currentlength;
        return this.notificationsService.getList(skip).pipe(
          map((response) =>
            notificationsActions.NotificationsSuccess({
              notifications: response.body ?? [],
              reset,
              total: Number(response.headers.get('X-Total-Count') ?? '0'),
              unread: Number(response.headers.get('X-Total-Unread') ?? '0'),
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(notificationsActions.NotificationsError())
          )
        );
      })
    )
  );

  head$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.NotificationsHeadRequest),
      mergeMap(() => {
        return this.notificationsService.head().pipe(
          map((response) =>
            notificationsActions.NotificationsHeadSuccess({
              total: Number(response.headers.get('X-Total-Count') ?? '0'),
              unread: Number(response.headers.get('X-Total-Unread') ?? '0'),
            })
          )
        );
      })
    )
  );

  read$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.NotificationReadRequest),
      mergeMap(({ notificationId, read }) =>
        (read
          ? this.notificationsService.read(notificationId)
          : this.notificationsService.unread(notificationId)
        ).pipe(
          map((notification) =>
            notificationsActions.NotificationReadSuccess({
              notification,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              notificationsActions.NotificationReadError({
                notificationId,
              })
            )
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.NotificationDeleteRequest),
      mergeMap(({ notificationId }) =>
        this.notificationsService.delete(notificationId).pipe(
          map(() =>
            notificationsActions.NotificationDeleteSuccess({ notificationId })
          ),
          catchError((er: HttpErrorResponse) =>
            of(notificationsActions.NotificationDeleteError({ notificationId }))
          )
        )
      )
    )
  );

  readAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.NotificationsReadRequest),
      switchMap(() =>
        this.notificationsService.readAll().pipe(
          map(() => notificationsActions.NotificationsReadSuccess()),
          catchError((er: HttpErrorResponse) =>
            of(notificationsActions.NotificationsReadError())
          )
        )
      )
    )
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.NotificationsDeleteRequest),
      switchMap(() =>
        this.notificationsService.deleteAll().pipe(
          map(() => notificationsActions.NotificationsDeleteSuccess()),
          catchError((er: HttpErrorResponse) =>
            of(notificationsActions.NotificationsDeleteError())
          )
        )
      )
    )
  );
}
