import { CigarLogService } from '@services/cigar-log.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ListCigarLogResolver  {
  constructor(
    private loader: LoaderService,
    private cigarLogService: CigarLogService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let id = route.params['CigarLogId'] || route.queryParams['CigarLogId'];
    id = Number.isNaN(Number(id)) ? id : Number(id);

    if (!id) {
      return of(undefined);
    }

    this.loader.show();
    return this.cigarLogService
      .getOne(id)
      .pipe(finalize(() => this.loader.hide()));
  }
}
