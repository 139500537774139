import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { EMPTY, Observable, from, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DeviceService } from '../services/device.service';
import { LocalStorageService } from '../services/local-storage.service';
import { RequestService } from '../services/request.service';
import { extractErrorMsg } from '../utils';
import { ToastService } from '@services/toast.service';

const alertId = 'interceptor';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  alert: HTMLIonAlertElement;
  hasAnError = false;
  presented = false;
  constructor(
    private deviceService: DeviceService,
    private requestService: RequestService,
    private alertCtrl: AlertController,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = this.requestService.prepareUrl(req.url);

    if (url.includes(environment.apiUrl)) {
      return from(this.localStorageService.get('authToken')).pipe(
        mergeMap((authToken) => {
          let headers = req.headers.set(
            'X-Device-UUID',
            this.deviceService.getDeviceID()
          );

          if (sessionStorage.connectionId) {
            headers = headers.set(
              'X-Device-RealtimeId',
              sessionStorage.connectionId
            );
          }

          if (authToken) {
            headers = headers.set('Authorization', `Bearer ${authToken}`);
          }

          const cloned = req.clone({
            headers: headers,
            url: url,
          });

          this.requestService.setLastRequest(cloned.url, cloned.body);

          return next.handle(cloned).pipe(
            tap({
              error: async (err) => {
                if (err instanceof HttpErrorResponse) {
                  if (err.status === 401) {
                    // this.store.dispatch(new AuthActions.Unauthorized());
                  } else {
                    console.log({ err });
                    await this.toastService.show(extractErrorMsg(err));
                  }
                }
              },
            }),
            catchError((err: HttpEvent<any>) => this.handleError(err))
          );
        })
      );
    }

    return next.handle(req);
  }

  private handleError(response: HttpEvent<any>) {
    if (response instanceof HttpErrorResponse) {
      if (this.requestService.handleError(response)) {
        return EMPTY;
      }
    }

    // console.log('message', extractErrorMsg(response));

    // if (this.hasAnError && this.presented) {
    //   this.alert.dismiss().then(() => this.present(extractErrorMsg(response)));
    // } else if (!this.hasAnError) {
    //   this.present(extractErrorMsg(response));
    // }
    return throwError(response);
  }

  present(message) {
    this.hasAnError = true;
    this.alertCtrl
      .create({
        id: alertId,
        header: 'Error occurred',
        subHeader: message,
        buttons: ['OK'],
      })
      .then((alert) => {
        this.alert = alert;
        console.log('handleError', { alert });
        alert.onDidDismiss().then(() => {
          console.log('handleError onDidDismiss');
          this.hasAnError = false;
          this.presented = false;
        });
        return alert.present();
      })
      .then(() => {
        this.presented = true;
      });
  }
}
