import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICigarLineInfo, ICigarLog, ICigarLogListType, IState } from '@models';
import {
    IProductAttribute,
    IProductAttributeAllTypes,
} from '@models/attributes';
import { Store } from '@ngrx/store';
import { CigarLogModel, JOURNAL_LIST } from '@shared/models/cigar-log.model';
import { ManualCigarEntryModel } from '@shared/models/manual-cigar-entry.model';
import { ProductModel } from '@shared/models/product.model';
import * as CigarsActions from '@store/actions/cigars';
import { isListHumidor } from '@utils/cigarlogs';
import { AppRoutes } from '@utils/routes';

const createPageTitle = 'Create Your Custom Cigar';
@Component({
    selector: 'custom-cigar',
    templateUrl: 'custom-cigar.html',
    styleUrls: ['custom-cigar.scss'],
})
export class CustomCigarPage {
    isLoading = false;
    humidorItem: CigarLogModel;
    pageTitle = createPageTitle;
    cigarData: ManualCigarEntryModel;
    cigarLog: ICigarLog;
    addTo: ICigarLogListType;
    isHumidor = false;

    manufacturers: IProductAttribute[];
    strengths: IProductAttribute[];
    origins: IProductAttribute[];
    wrappers: IProductAttribute[];
    binders: IProductAttribute[];
    fillers: IProductAttribute[];
    wrapperColors: IProductAttribute[];

    textButton = 'Save';

    constructor(private route: ActivatedRoute, private store: Store<IState>) { }

    ionViewWillEnter() {
        const data = this.route.snapshot.data as {
            cigarlog: ICigarLog;
            attributes: IProductAttributeAllTypes;
            lineInfo: ICigarLineInfo;
        };
        this.cigarLog = data.cigarlog;
        this.addTo =
            this.cigarLog?.List ||
            this.route.snapshot.queryParams.addTo ||
            JOURNAL_LIST;
        this.isHumidor = isListHumidor(this.addTo);
        this.pageTitle = data.cigarlog
            ? data.cigarlog.CigarDetails.Name
            : createPageTitle;

        if (this.cigarLog) {
            this.cigarData = new ManualCigarEntryModel(
                new ProductModel(this.cigarLog.CigarDetails)
            );
            this.textButton = 'Save';
        } else {
            this.cigarData = new ManualCigarEntryModel();
            this.textButton = 'Create';
        }

        if (data.attributes) {
            this.manufacturers = data.attributes.manufacturers;
            this.strengths = data.attributes.strengths;
            this.origins = data.attributes.origins;
            this.wrappers = data.attributes.wrappers;
            this.binders = data.attributes.binders;
            this.fillers = data.attributes.fillers;
            this.wrapperColors = data.attributes.wrapperColors;
        }

        // case where you click "Create a New Size"
        if (data.lineInfo) {
            this.cigarData.LineId = data.lineInfo.LineId;
            this.cigarData.ManufacturerValueId = data.lineInfo.ManufacturerValueId;
            this.cigarData.StrengthValueId = data.lineInfo.StrengthValueId;
            this.cigarData.OriginValueId = data.lineInfo.OriginValueId;
            this.cigarData.WrapperValueId = data.lineInfo.WrapperValueId;
            this.cigarData.BinderValueId = data.lineInfo.BinderValueId;
            this.cigarData.FillerValueId = data.lineInfo.FillerValueId;
            this.cigarData.WrapperColorValueId = data.lineInfo.WrapperColorValueId;
        }
    }

    submit(valid) {
        if (!valid) {
            return;
        }

        if (this.cigarLog) {
            this._edit();
        } else {
            this._add();
        }
    }

    private _add() {
        this.isLoading = true;
        this.store.dispatch(
            CigarsActions.CigarCreateCustomRequest({
                form: {
                    Name: this.cigarData.Name,
                    Description: this.cigarData.Description,
                    ManufacturerValueId: this.cigarData.ManufacturerValueId,
                    StrengthValueId: this.cigarData.StrengthValueId,
                    OriginValueId: this.cigarData.OriginValueId,
                    WrapperValueId: this.cigarData.WrapperValueId,
                    BinderValueId: this.cigarData.BinderValueId,
                    FillerValueId: this.cigarData.FillerValueId,
                    WrapperColorValueId: this.cigarData.WrapperColorValueId,
                },
                addTo: this.addTo,
            })
        );
    }

    private _edit() {
        this.isLoading = true;
        this.store.dispatch(
            CigarsActions.CigarUpdateCustomRequest({
                form: {
                    Name: this.cigarData.Name,
                    Description: this.cigarData.Description,
                    ManufacturerValueId: this.cigarData.ManufacturerValueId,
                    StrengthValueId: this.cigarData.StrengthValueId,
                    OriginValueId: this.cigarData.OriginValueId,
                    WrapperValueId: this.cigarData.WrapperValueId,
                    BinderValueId: this.cigarData.BinderValueId,
                    FillerValueId: this.cigarData.FillerValueId,
                    WrapperColorValueId: this.cigarData.WrapperColorValueId,
                },
                ProductId: this.cigarLog.ProductId,
                redirectTo: this.isHumidor
                    ? AppRoutes.myHumidor(this.addTo)
                    : AppRoutes.myCigars(),
            })
        );
    }
}
