import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProductService } from '@services/product.service';

@Injectable({
  providedIn: 'root',
})
export class AttributesResolver  {
  constructor(private productService: ProductService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.productService.getAllProductAttributeValues();
  }
}
