import { Observable, Observer } from 'rxjs';

export class BaseSocketEventHandler {

  protected resourceName;
  protected zone;

  constructor(zone) {
    this.zone = zone;
  }

  /**
   * Update local database and currently loaded data.
   */
  _update(dbUpdateFn: (data: any) => any, dataStoreUpdateFn: (data: any) => any, data: any) {
    const res = dbUpdateFn(data);

    if (res && dataStoreUpdateFn) {
      this.zone.run(() => {
        dataStoreUpdateFn(res);
      });
    }
  }

}
