import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { ModalService } from '@services/modal.service';
import { KioskCartModel } from '@shared/models/kiosk-cart.model';
import { MicrostoreResource } from '@shared/resources/microstore-resource';
import { LoaderService } from '@shared/services/loader.service';
import { addIcons } from "ionicons";
import { closeOutline } from "ionicons/icons";

@Component({
    selector: 'order-details',
    templateUrl: './order-details.html',
    styleUrls: ['./order-details.scss'],
})
export class OrderDetails implements OnInit {
    @Input() order: KioskCartModel;

    constructor(
        private microstoreResource: MicrostoreResource,
        private loader: LoaderService,
        private alertCtrl: AlertController,
        private modalService: ModalService
    ) {
        addIcons({ closeOutline });
    }

    ngOnInit() { }

    approveOrder() {
        this.loader.show();
        this.microstoreResource.approveOrder(this.order.Id).subscribe(
            (res) => {
                this.loader.hide();
                this.modalService.dismiss();
            },
            (err) => {
                this.alertCtrl
                    .create({
                        header: 'Error occurred',
                        subHeader: err.message,
                        buttons: ['OK'],
                    })
                    .then((alert) => {
                        alert.present();
                    });
                this.loader.hide();
            }
        );
    }

    dismiss() {
        this.modalService.dismiss();
    }
}
