<div class="settings-wrapper">
  <div class="active-kiosk-wrapper">
    <ion-label>Active Kiosk:</ion-label>
    <ion-select [(ngModel)]="kiosk" (ngModelChange)="setActiveKiosk($event)">
      <ion-select-option *ngFor="let kiosk of kiosks" [value]="kiosk">{{
        kiosk.Name
      }}</ion-select-option>
    </ion-select>
  </div>

  <div class="retailer-info">
    <div><span>Retailer Name: </span>{{ kiosk.RetailerName }}</div>
    <div><span>Business Name: </span>{{ kiosk.BusinessName }}</div>
    <div><span>Address: </span>{{ kiosk.RetailerAddress }}</div>
    <div><span>Address2: </span>{{ kiosk.RetailerAddress2 }}</div>
    <div>
      <span>City, State Zip: </span>{{ kiosk.RetailerCity }},
      {{ kiosk.RetailerState }}, {{ kiosk.RetailerZipCode }}
      {{ kiosk.RetailerCountry }}
    </div>
    <div><span>Phone: </span>{{ kiosk.RetailerPhoneNumber }}</div>
    <div><span>Description: </span>{{ kiosk.Description }}</div>
  </div>

  <form
    #kioskSettingsForm="ngForm"
    class="kiosk-settings"
    (ngSubmit)="updateStore(kioskSettingsForm)"
  >
    <div>
      <span>Discount (+/- %)</span>
      <ion-input
        type="number"
        placeholder="Enter % Discount"
        name="discount"
        [(ngModel)]="kiosk.Discount"
      ></ion-input>
    </div>
    <div>
      <span>Min Order ($)</span>
      <ion-input
        type="number"
        placeholder="Enter Minimum Order amount"
        name="minOrder"
        [(ngModel)]="kiosk.MinOrder"
      ></ion-input>
    </div>
    <ion-button
      size="large"
      color="primary"
      [disabled]="kioskSettingsForm.pristine"
      type="submit"
    >
      Save
    </ion-button>
  </form>

  <h1>Authorized Users</h1>
  <div class="users">
    <form
      #userForm="ngForm"
      class="user"
      *ngFor="let user of kiosk.Users"
      (ngSubmit)="updateUser(userForm, user)"
    >
      <div class="avatar">
        <img
          [src]="user.AvatarUrl | createSource"
          src-fallback="assets/images/user-image.png"
        />
      </div>
      <div class="info">
        <div class="name-email-wrapper">
          <div class="name">{{ user | userName }}</div>
          <div class="email">{{ user.Email }}</div>
        </div>

        <ion-item>
          <ion-label>Enabled</ion-label>
          <ion-toggle
            [disabled]="user.UserId === activeUserService.getID()"
            name="isEnabled"
            [(ngModel)]="user.IsEnabled"
          ></ion-toggle>
        </ion-item>

        <ion-item>
          <ion-label>Admin</ion-label>
          <ion-toggle
            [disabled]="user.UserId === activeUserService.getID()"
            name="isAdmin"
            [(ngModel)]="user.IsAdmin"
          ></ion-toggle>
        </ion-item>

        <div class="button-wrapper">
          <ion-button [disabled]="userForm.pristine" type="submit">
            Save
          </ion-button>
          <ion-button
            [disabled]="user.UserId === activeUserService.getID()"
            class="delete-btn"
            type="button"
            (click)="removeUserAlert(user)"
          >
            delete
          </ion-button>
        </div>
      </div>
    </form>
  </div>
</div>
