import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ModalService } from '@services/modal.service';
import { addIcons } from "ionicons";
import { closeOutline } from "ionicons/icons";
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonFooter } from "@ionic/angular/standalone";

@Component({
    standalone: true,
    imports: [CommonModule, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonFooter],
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <img [src]="imgSrc" alt="add to homescreen tutorial" />
    </ion-content>

    <ion-footer> </ion-footer>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
      ion-textarea {
        width: 100%;
      }
      :host ion-footer ::ng-deep span {
        pointer-events: none;
      }
    `,
    ],
})
export class PWATutorialModal implements OnInit {
    @Input() browser: 'ios-safari' | 'ios-browser';
    title = '';
    imgSrc = '';

    constructor(private modalService: ModalService) {
        addIcons({ closeOutline });
    }

    ngOnInit() {
        if (this.browser === 'ios-safari') {
            this.title = 'Install CigarScanner';
            this.imgSrc =
                'https://az571366.vo.msecnd.net/res/CigarScanner_HomeScreenIosFinal.jpg';
        } else if (this.browser === 'ios-browser') {
            this.title = 'Install CigarScanner on Safari';
            this.imgSrc =
                'https://az571366.vo.msecnd.net/res/CigarScanner_HomeScreenChromeIos.jpg';
        }
    }

    dismiss() {
        this.modalService.dismiss({
            dismissed: true,
        });
    }
}
