import { LayoutModule } from '@angular/cdk/layout';
import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { ThemeDetection } from '@awesome-cordova-plugins/theme-detection/ngx';
import { BarcodeFormat as NativeBarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import {
  IonApp,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonRouterOutlet,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonSplitPane,
  IonText,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  Platform,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { PWATutorialModal } from '@modals/pwa-tuto-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ScannerModule } from '@services/scanner/scanner.module';
import { SharedComponentsModule } from '@shared/components';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { SharedPipesModule } from '@shared/pipes';
import { DeviceService } from '@shared/services/device.service';
import { AppErrorHandler } from '@shared/services/error.handler';
import { SharedModule } from '@shared/shared.module';
import { RootEffects } from '@store/effects';
import { metaReducers, reducers } from '@store/reducers';
import { addIcons } from 'ionicons';
import { chevronDownOutline } from 'ionicons/icons';
import { ImageCropperModule } from 'ngx-img-cropper';
import { ImgFallbackModule } from 'ngx-img-fallback';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomCigarPage } from './components/custom-cigar/custom-cigar';
import { DocumentPresenter } from './components/document-presenter/document-presenter';
import { DocumentPresenterModal } from './components/document-presenter/document-presenter.modal';
import { LayoutComponent } from './components/layout/layout.component';
import { MicrostorePage } from './components/microstore/microstore';
import { MicrostoreSettingsPage } from './components/microstore/microstore-settings/microstore-settings';
import { OrderDetails } from './components/microstore/order-details/order-details';
import { Order } from './components/microstore/order/order';
import { PastOrdersPage } from './components/microstore/past-orders/past-orders';
import { PendingOrdersPage } from './components/microstore/pending-orders/pending-orders';
import { MyProfilePage } from './components/my-profile/my-profile';
import { Eula } from './components/social/eula/eula';
import { ReportPopover } from './components/social/report-popover/report-popover';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    Eula,
    MyProfilePage,
    DocumentPresenter,
    DocumentPresenterModal,
    CustomCigarPage,
    MicrostorePage,
    PastOrdersPage,
    ReportPopover,
    PendingOrdersPage,
    MicrostoreSettingsPage,
    Order,
    OrderDetails,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ImageCropperModule,
    SharedModule,
    SharedPipesModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(RootEffects),
    LayoutModule,
    ...environment.imports,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ScannerModule.forRoot({
      proposeToInstallGoogleNativeScanner: false,
      webFormats: [
        // 'code_128_reader',
        'ean_reader',
        // 'ean_5_reader',
        // 'ean_2_reader',
        // 'ean_8_reader',
        // 'code_39_reader',
        // 'code_39_vin_reader',
        // 'codabar_reader',
        'upc_reader',
        'upc_e_reader',
        // 'i2of5_reader',
        // '2of5_reader',
        // 'code_93_reader',
        // 'code_32_reader',
      ],
      nativeFormats: [
        // NativeBarcodeFormat.Aztec,
        // NativeBarcodeFormat.Codabar,
        // NativeBarcodeFormat.Code39,
        // NativeBarcodeFormat.Code128,
        // NativeBarcodeFormat.DataMatrix,
        // NativeBarcodeFormat.Ean8,
        NativeBarcodeFormat.Ean13,
        // NativeBarcodeFormat.Itf,
        // NativeBarcodeFormat.Pdf417,
        NativeBarcodeFormat.UpcA,
        NativeBarcodeFormat.UpcE,
      ],
      nativeFallbackActiveClass: 'barcode-scanner-active',
      nativeFallbackClass: 'native-scanner-modal',
    }),
    ImgFallbackModule,
    PWATutorialModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonNote,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonIcon,
    IonProgressBar,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSegment,
    IonSegmentButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFab,
    IonFabButton,
    IonToggle,
    IonText,
    IonBadge,
    IonRefresher,
    IonRefresherContent,
    IonCheckbox,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonFooter,
  ],
  providers: [
    ThemeDetection,
    DecimalPipe,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (deviceService: DeviceService) => () => deviceService.init(),
      deps: [DeviceService],
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: (platform: Platform) => ({
        enabled: !platform.is('hybrid') && environment.production,
      }),
      deps: [Platform],
    },
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true,
      swipeBackEnabled: true,
      refreshingIcon: 'chevron-down-outline',
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // used by provideIonicAngular
    addIcons({ chevronDownOutline });
  }
}
