import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CigarsService } from '@services/cigars.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductLineInfoResolver  {
  constructor(private cigarsService: CigarsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return route.queryParams.lineId
      ? this.cigarsService.getLineInfo(route.queryParams.lineId)
      : of(undefined);
  }
}
