import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CigarsService } from '@services/cigars.service';
import { ToastService } from '@services/toast.service';
import * as CigarsActions from '@store/actions/cigars';
import * as myCigarsActions from '@store/actions/my-cigars';
import { isListHumidor } from '@utils/cigarlogs';
import { getErrorMessage } from '@utils/errors';
import { AppRoutes } from '@utils/routes';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

@Injectable()
export class CigarsEffects {
  constructor(
    private readonly actions$: Actions,
    private cigarsService: CigarsService,
    private toastService: ToastService,
    private router: Router
  ) {}
  reviewToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CigarsActions.CigarUpdateReviewSuccess),
        map(() => this.toastService.show('Review saved'))
      ),
    {
      dispatch: false,
    }
  );

  getOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarRequest),
      switchMap(({ cigarType, id }) =>
        this.cigarsService.get(cigarType, id).pipe(
          map((cigar) => CigarsActions.CigarSuccess({ cigar })),
          catchError((er: HttpErrorResponse) =>
            of(CigarsActions.CigarError({ error: getErrorMessage(er) }))
          )
        )
      )
    )
  );

  review$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarUpdateReviewRequest),
      mergeMap(({ cigar, review }) =>
        this.cigarsService.review(cigar, review).pipe(
          map((newReview) =>
            CigarsActions.CigarUpdateReviewSuccess({
              cigar,
              review: newReview,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarUpdateReviewError({
                cigar,
                error: getErrorMessage(er),
              })
            )
          )
        )
      )
    )
  );

  updateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarUpdateNoteRequest),
      mergeMap(({ cigar, text }) =>
        this.cigarsService.saveNote(cigar, text).pipe(
          map((note) => CigarsActions.CigarUpdateNoteSuccess({ cigar, note })),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarUpdateNoteError({
                cigar,
                error: getErrorMessage(er),
              })
            )
          )
        )
      )
    )
  );

  deleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarDeleteNoteRequest),
      switchMap(({ cigar }) =>
        this.cigarsService.deleteNote(cigar).pipe(
          map(() => {
            return CigarsActions.CigarDeleteNoteSuccess({ cigar, note: null });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarDeleteNoteError({
                cigar,
                error: getErrorMessage(er),
              })
            )
          )
        )
      )
    )
  );

  deleteReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarDeleteReviewRequest),
      switchMap(({ cigar }) =>
        this.cigarsService.deleteReview(cigar).pipe(
          map(() => {
            return CigarsActions.CigarDeleteReviewSuccess({
              cigar,
              review: null,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarDeleteReviewError({
                cigar,
                error: getErrorMessage(er),
              })
            )
          )
        )
      )
    )
  );

  updateFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarUpdateFeaturesRequest),
      mergeMap(({ cigar, form }) => {
        // const loader = from(this.loadingCtrl.create()).subscribe();
        return this.cigarsService.saveFeatures(cigar, form).pipe(
          map((features) => {
            return CigarsActions.CigarUpdateFeaturesSuccess({
              cigar,
              features,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarUpdateFeaturesError({
                cigar,
                error: getErrorMessage(er),
              })
            )
          )
        );
      })
    )
  );

  onCustomCigarCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarCreateCustomSuccess),
      filter(({ addTo }) => !!addTo),
      map(({ cigar, addTo }) => {
        this.router.navigateByUrl(
          isListHumidor(addTo)
            ? AppRoutes.myHumidor(addTo)
            : AppRoutes.myCigars()
        );

        return myCigarsActions.MyCigarsCreateRequestAction({
          LineId: cigar.LineId,
          ProductId: cigar.ProductId,
          listType: addTo,
        });
      })
    )
  );

  createCustom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarCreateCustomRequest),
      switchMap(({ form, addTo }) =>
        this.cigarsService.createCustom(form).pipe(
          map((cigar) =>
            CigarsActions.CigarCreateCustomSuccess({ cigar, addTo })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarCreateCustomError({
                error: getErrorMessage(er),
              })
            )
          )
        )
      )
    )
  );

  updateCustom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsActions.CigarUpdateCustomRequest),
      switchMap(({ ProductId, form, redirectTo }) =>
        this.cigarsService.updateCustom(ProductId, form).pipe(
          map((cigar) => {
            if (redirectTo) {
              this.router.navigateByUrl(redirectTo);
            }
            return CigarsActions.CigarUpdateCustomSuccess({ cigar });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              CigarsActions.CigarUpdateCustomError({
                error: getErrorMessage(er),
              })
            )
          )
        )
      )
    )
  );
}
