import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { ToastService } from '@services/toast.service';
import { UserModel } from '@shared/models/user.model';
import { AccountService } from '@shared/services/account.service';
import { ActiveUserService } from '@shared/services/active-user.service';
import { CameraService } from '@shared/services/camera.service';
import { LoaderService } from '@shared/services/loader.service';
import { getErrorMessage } from '@utils/errors';
import { addIcons } from 'ionicons';
import { checkmark, close } from 'ionicons/icons';
import _assignIn from 'lodash-es/assignIn';
import _cloneDeep from 'lodash-es/cloneDeep';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';

@Component({
  selector: 'my-profile',
  templateUrl: 'my-profile.html',
  styleUrls: ['my-profile.scss'],
})
export class MyProfilePage implements OnInit, OnDestroy {
  originalUserData: UserModel;
  userData: UserModel;
  changePassword = false;
  showCropper = false;
  imageSubmitted = false;

  data: any;
  cropperSettings: CropperSettings;

  @ViewChild('cropper', { static: false })
  cropper: ImageCropperComponent;

  constructor(
    private accountService: AccountService,
    private activeUserService: ActiveUserService,
    private alertCtrl: AlertController,
    private cameraService: CameraService,
    private loader: LoaderService,
    private toastService: ToastService
  ) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 200;
    this.cropperSettings.height = 200;

    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;

    this.cropperSettings.canvasWidth = 700;
    this.cropperSettings.canvasHeight = 400;

    if (window.innerWidth < 1200) {
      this.cropperSettings.canvasWidth = window.innerWidth;
      this.cropperSettings.canvasHeight = 300;
    }

    this.cropperSettings.minWidth = 100;
    this.cropperSettings.minHeight = 100;

    this.cropperSettings.rounded = false;

    this.cropperSettings.cropperDrawSettings.strokeColor =
      'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.cropperSettings.noFileInput = true;
    this.data = {};
    addIcons({ close, checkmark });
  }

  ngOnInit() {
    this.userData = this.activeUserService.user();
    this.originalUserData = _cloneDeep(this.userData);
  }

  async takePicture() {
    try {
      const { image } = await this.cameraService.showCameraOptions();
      const imageHTML = new Image();
      imageHTML.src = image.data;
      this.cropper.setImage(imageHTML);
      this.showCropper = true;
    } catch (error) {
      await this.toastService.show(getErrorMessage(error));
    }
  }

  submit(form) {
    if (!form.valid) {
      this.alertCtrl
        .create({
          header: 'Error occurred',
          subHeader: 'You have errors to correct',
          buttons: ['OK'],
        })
        .then((alert) => {
          alert.present();
          return;
        });
    }

    this.loader.show();
    this.accountService.update(this.userData).subscribe(
      async () => {
        this.loader.hide();
        this.changePassword = false;
        _assignIn(this.originalUserData, this.userData);
        await this.toastService.show('Profile updated successfully');
      },
      (err) => {
        this.loader.hide();
      }
    );
  }

  cancelAvatarChange() {
    this.showCropper = false;
  }

  submitImage() {
    this.loader.show();
    this.imageSubmitted = true;

    this.accountService
      .uploadProfilePicture(this.data.image)
      .subscribe((url: string) => {
        this.accountService.changeProfilePicture(url).subscribe(
          async () => {
            this.loader.hide();
            _assignIn(this.originalUserData, this.userData);
            this.showCropper = false;
            await this.toastService.show(
              'Profile picture updated successfully'
            );
          },
          (err) => {
            this.loader.hide();
          }
        );
      });
  }

  removeImage() {
    this.accountService.changeProfilePicture().subscribe(
      async () => {
        this.loader.hide();
        _assignIn(this.originalUserData, this.userData);
        this.showCropper = false;
        await this.toastService.show('Profile picture removed');
      },
      () => {
        this.loader.hide();
      }
    );
  }

  ngOnDestroy() {
    if (this.imageSubmitted) {
      _assignIn(this.userData, this.originalUserData);
    }
  }
}
