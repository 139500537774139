import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { KioskCartModel } from '@shared/models/kiosk-cart.model';

@Component({
    selector: 'past-orders',
    templateUrl: './past-orders.html',
})
export class PastOrdersPage implements OnInit {
    pageTitle: string;
    orders: KioskCartModel[];
    constructor(private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data: any) => {
            this.pageTitle = data.title;
            this.orders = data.orders;
        });
    }
}
