import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IState, IUserSettings } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PushNotificationsService } from '@services/push-notifications.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { UserModel } from '@shared/models/user.model';
import { ActiveUserService } from '@shared/services/active-user.service';
import { FacebookAuthService } from '@shared/services/auth/facebook-auth.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SocketHandlerService } from '@shared/services/socket-handler.service';
import { StorageService } from '@shared/services/storage.service';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class UserEffects {
  constructor(
    private store: Store<IState>,
    private router: Router,
    private readonly actions$: Actions<userActions.ActionsUnion>,
    private userService: UserService,
    private pushNotificationsService: PushNotificationsService,
    private toastService: ToastService,
    private socketHandlerService: SocketHandlerService,
    private localStorageService: LocalStorageService,
    private facebookAuthService: FacebookAuthService,
    private activeUserService: ActiveUserService,
    private storageService: StorageService
  ) {}

  requestSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.SummaryRequest),
      switchMap(() =>
        this.userService.getDataSummary().pipe(
          map((summary) => userActions.SummarySuccess({ summary })),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        )
      )
    )
  );

  agreeToTrems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.AgreeToTermsRequest),
      switchMap(() =>
        this.userService.agreeToTerms().pipe(
          map(() => userActions.AgreeToTermsSuccess()),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        )
      )
    )
  );

  requestSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.SettingsRequest),
      withLatestFrom(this.store.select(userSelectors.selectSettings)),
      switchMap(([action, settings]) => {
        if (settings !== null) {
          return of(userActions.SettingsSuccess({ settings }));
        }
        return this.userService.getSettings().pipe(
          map((response) =>
            userActions.SettingsSuccess({ settings: response })
          ),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.SettingsUpdateRequest),
      withLatestFrom(this.store.select(userSelectors.selectSettings)),
      switchMap(([{ settings }, oldSettings]) => {
        if (detectNotificationsChange(oldSettings, settings)) {
          this.pushNotificationsService.askForPermission();
        }
        return this.userService.updateSettings(settings).pipe(
          map((response) =>
            userActions.SettingsUpdateSuccess({ settings: response })
          ),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UserDeleteRequest),
      switchMap(() => {
        return this.userService.deleteUser().pipe(
          map(() => {
            this.toastService.show('Your account has been deleted');
            return userActions.UserDeleteSuccess();
          }),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LogoutRequest),
      switchMap(() => {
        this.socketHandlerService.stop();
        return this.userService.logout().pipe(
          map(() => userActions.LogoutSuccess()),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  logoutAfterAccountDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UserDeleteSuccess),
      map(() => userActions.LogoutRequest())
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LoginSuccess),
      map(() => {
        this.pushNotificationsService.register();
        return userActions.SummaryRequest();
      })
    )
  );

  logoutRedirection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.LogoutSuccess),
        tap(async () => {
          this.facebookAuthService.logout();
          await this.localStorageService.remove('authToken');
          this.activeUserService.setActiveUser({} as UserModel);
          this.socketHandlerService.start();
          this.pushNotificationsService.unregister();
          this.storageService.clear();
          this.store.dispatch(userActions.SummaryRequest());
          this.router.navigate(['login']);
        })
      ),
    { dispatch: false }
  );
}

function detectNotificationsChange(
  oldSettings: IUserSettings,
  newSettings: Partial<IUserSettings>
) {
  return (
    (newSettings.NotifyAboutLikes && !oldSettings.NotifyAboutLikes) ||
    (newSettings.NotifyAboutNewFollowers &&
      !oldSettings.NotifyAboutNewFollowers) ||
    (newSettings.NotifyAboutComments && !oldSettings.NotifyAboutComments)
  );
}
