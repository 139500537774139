import { Injectable } from '@angular/core';
import { ICigar, IState } from '@models';
import { ISocketChannelEnum, ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as CigarsActions from '@store/actions/cigars';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class ProductSocketEventHandler implements SocketEventHandlerInterface {
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<ICigar>) {
    // do not implement yet as if a new cigar is created
    // it won't be in memory anywhere.
    // don't see the point
  }

  deleted(socketMessage: ISocketParsedMessage<string>) {
    // Do not remove cigar from ngrx yet since
    // it will breaks cigarlogs that use them
    // it will stay on memory but that's ok
  }

  updated(socketMessage: ISocketParsedMessage<ICigar>) {
    if (ISocketChannelEnum.GLOBAL === socketMessage.Channel) {
      return;
    }

    this.store.dispatch(
      CigarsActions.CigarSuccess({
        cigar: socketMessage.Data,
      })
    );
  }
}
