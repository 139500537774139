import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { KioskCartModel } from '../models/kiosk-cart.model';
import { KioskModel } from '../models/kiosk.model';
import { MicrostoreResource } from '../resources/microstore-resource';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class PastOrdersResolver  {
  constructor(
    private microstoreResource: MicrostoreResource,
    private loader: LoaderService

  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.loader.show();
    return new Observable((o: Observer<any>) => {
      this.microstoreResource.getKiosks()
        .subscribe((kiosks: KioskModel[]) => {
          this.microstoreResource.getPastOrders(this.microstoreResource.getActiveKiosk().Id).subscribe(
            (carts: KioskCartModel[]) => {
              this.loader.hide();
              o.next(carts);
              return o.complete();
            }
          );
        });
    });
  }
}
