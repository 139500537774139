import { Injectable, NgZone } from '@angular/core';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import debug from 'debug';

import { SocketMessageModel } from '../../models/socket-message.model';
import { BaseSocketEventHandler } from './base.socket-event-handler';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';

const log = debug('ProductReviewSocket');

/**
 *
 * THIS IS COMMENTED BECAUSE I DON"T SEE THE POINT RECEVING EVERYONE ELSE'S RATINGS
 *
 */
@Injectable()
export class ProductReviewSocketEventHandler
  extends BaseSocketEventHandler
  implements SocketEventHandlerInterface
{
  constructor(
    protected zone: NgZone,
    // private productSocketEventHandler: ProductSocketEventHandler,
    // private activeUserService: ActiveUserService,
    private store: Store<IState>
  ) {
    super(zone);
    this.resourceName = 'CigarRating';
  }

  created(socketMessage: SocketMessageModel) {
    log('created', { socketMessage });
    // this.upsert(socketMessage);
  }

  deleted(socketMessage: SocketMessageModel) {
    // this._updateProductRating(socketMessage);
  }

  updated(socketMessage: SocketMessageModel) {
    log('updated', { socketMessage });
    // this.upsert(socketMessage);
  }

  // private upsert(socketMessage: SocketMessageModel) {
  //   try {
  //     if (!socketMessage.Data) return;
  //     this._updateProductRating(socketMessage);

  //     // only update our own rating
  //     if (socketMessage.Data.UserId !== this.activeUserService.getID()) return;

  //     const cigar = {
  //       ProductId: socketMessage.Data.ProductId,
  //       LineId: socketMessage.Data.LineId,
  //     } as ICigar;

  //     this.store.dispatch(
  //       CigarsActions.CigarUpdateReviewSuccessAction({
  //         cigar,
  //         review: socketMessage.Data,
  //       })
  //     );
  //   } catch (error) {
  //     log({ error });
  //   }
  // }

  // private _updateProductRating(socketMessage: SocketMessageModel) {
  //   let productData, lineData;

  //   if (socketMessage.Data && socketMessage.Data.Extra) {
  //     if (socketMessage.Data.Extra.ProductId) {
  //       const productRating = new ProductRatingModel({
  //         AverageRating: socketMessage.Data.Extra.ProductAverageRating,
  //         RatingCount: socketMessage.Data.Extra.ProductRatingCount,
  //         Rated5: socketMessage.Data.Extra.ProductRated5,
  //         Rated4: socketMessage.Data.Extra.ProductRated4,
  //         Rated3: socketMessage.Data.Extra.ProductRated3,
  //         Rated2: socketMessage.Data.Extra.ProductRated2,
  //         Rated1: socketMessage.Data.Extra.ProductRated1,
  //       });

  //       productData = new ProductModel({
  //         ProductId: socketMessage.Data.Extra.ProductId,
  //         LineId: socketMessage.Data.Extra.LineId,
  //         RatingSummary: productRating,
  //       });
  //     }

  //     if (socketMessage.Data.Extra.LineId) {
  //       const lineRating = new ProductRatingModel({
  //         AverageRating: socketMessage.Data.Extra.LineAverageRating,
  //         RatingCount: socketMessage.Data.Extra.LineRatingCount,
  //         Rated5: socketMessage.Data.Extra.LineRated5,
  //         Rated4: socketMessage.Data.Extra.LineRated4,
  //         Rated3: socketMessage.Data.Extra.LineRated3,
  //         Rated2: socketMessage.Data.Extra.LineRated2,
  //         Rated1: socketMessage.Data.Extra.LineRated1,
  //       });

  //       lineData = new ProductModel({
  //         LineId: socketMessage.Data.Extra.LineId,
  //         RatingSummary: lineRating,
  //       });
  //     }

  //     delete socketMessage.Data.Extra;
  //   }

  //   if (productData) {
  //     this.productSocketEventHandler.update(productData);
  //   }

  //   if (lineData) {
  //     this.productSocketEventHandler.update(lineData);
  //   }
  // }
}
