import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { KioskCartModel } from '../models/kiosk-cart.model';
import { KioskModel } from '../models/kiosk.model';

import { MicrostoreResource } from '../resources/microstore-resource';

@Injectable({
  providedIn: 'root'
})
export class PendingOrdersResolver  {
  constructor(
    private microstoreResource: MicrostoreResource
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable((o: Observer<any>) => {
      this.microstoreResource.getKiosks()
        .subscribe((kiosks: KioskModel[]) => {
          this.microstoreResource.getCarts(this.microstoreResource.getActiveKiosk().Id).subscribe(
            (carts: KioskCartModel[]) => {
              o.next(carts);
              return o.complete();
            }
          );
        });
    });
  }
}
