import { HumidorsEffects } from './humidors';
import { BannersEffects } from './banners';
import { CigarsEffects } from './cigars';
import { MyCigarsEffects } from './my-cigars';
import { UserEffects } from './user';
import { CigarsSearchEffects } from './cigars-search';
import { NotificationsEffects } from './notifications';
import { UsersSearchEffects } from './users-search';
import { SocialEffects } from './social';

export const RootEffects = [
  HumidorsEffects,
  BannersEffects,
  CigarsEffects,
  MyCigarsEffects,
  UserEffects,
  CigarsSearchEffects,
  NotificationsEffects,
  UsersSearchEffects,
  SocialEffects,
];
