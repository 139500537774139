import { Injectable } from '@angular/core';

import { SocketMessageModel } from '../../models/socket-message.model';
import { ActiveUserService } from '../active-user.service';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class UserSocketEventHandler implements SocketEventHandlerInterface {

  constructor(
    private activeUserService: ActiveUserService
  ) { }

  created(socketMessage: SocketMessageModel) { }

  deleted(socketMessage: SocketMessageModel) { }

  updated(socketMessage: SocketMessageModel) {
    this.activeUserService.update({
      Email: socketMessage.Data.Email,
      FirstName: socketMessage.Data.FirstName,
      LastName: socketMessage.Data.LastName,
      AvatarUrl: socketMessage.Data.AvatarUrl
    });
  }

}
