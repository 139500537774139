import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import { IState } from '@models';

import humidors, { NAMESPACE as HumidorsNAMESPACE } from './humidors';
import banners from './banners';
import cigars from './cigars';
import myCigars from './my-cigars';
import user, { NAMESPACE as UserNAMESPACE } from './user';
import cigarsSearch, {
  NAMESPACE as CigarsSearchNAMESPACE,
} from './cigars-search';
import usersSearch, { NAMESPACE as UsersSearchNAMESPACE } from './users-search';
import notifications, {
  NAMESPACE as NotificationsNAMESPACE,
} from './notifications';
import social, { NAMESPACE as SocialNAMESPACE } from './social';

export const reducers: ActionReducerMap<IState> = {
  [HumidorsNAMESPACE]: humidors,
  banners,
  cigars,
  myCigars,
  [UserNAMESPACE]: user,
  [CigarsSearchNAMESPACE]: cigarsSearch,
  [NotificationsNAMESPACE]: notifications,
  [UsersSearchNAMESPACE]: usersSearch,
  [SocialNAMESPACE]: social,
};

export const metaReducers: MetaReducer<IState>[] = !environment.production
  ? []
  : [];
