import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AccountService } from './account.service';
import { ActiveUserService } from './active-user.service';
import { LoaderService } from './loader.service';

import * as userActions from '@store/actions/user';
import { Store } from '@ngrx/store';
import { IState } from '@models';
@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private lastRequest = {} as any;

  constructor(
    private activeUserService: ActiveUserService,
    private loader: LoaderService,
    private store: Store<IState>
  ) {}

  prepareUrl(url: string) {
    const pattern = /^https?:\/\/|^\/\//i;

    if (!pattern.test(url)) {
      url = `${environment.apiUrl}/${url}`;
    }

    return url;
  }

  handleError(response: HttpErrorResponse) {
    this.lastRequest.error = {
      status: response.status,
      text: response.statusText,
    };

    if (
      response.status === 401 &&
      !response.url.endsWith('account') &&
      !response.url.endsWith('account/logout') &&
      this.activeUserService.isAuthenticated()
    ) {
      this.store.dispatch(userActions.LogoutRequest());
      this.loader.hide();
      return true;
    }

    return false;
  }

  setLastRequest(url: string, body) {
    this.lastRequest = {
      url: url,
    };

    if (body) {
      this.lastRequest.body = body;
    }
  }

  getLastRequest() {
    return this.lastRequest;
  }
}
