import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonButtons,
  IonBackButton,
  Platform,
} from '@ionic/angular/standalone';
import { AlertController } from '@ionic/angular/standalone';
import { RxLet } from '@rx-angular/template/let';
import { ResetPasswordModel } from '@shared/models/reset-password.model';
import { AccountService } from '@shared/services/account.service';
import { LoaderService } from '@shared/services/loader.service';
import { AppRoutes } from '@utils/routes';

function MatchPassword(password: string, confirmPassword: string) {
  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[password];
    const confirmPasswordControl = formGroup.controls[confirmPassword];
    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (
      confirmPasswordControl.errors &&
      !confirmPasswordControl.errors.passwordMismatch
    ) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  };
}
@Component({
  selector: 'reset-password',
  standalone: true,
  imports: [
    IonLabel,
    CommonModule,
    ReactiveFormsModule,
    RxLet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonButtons,
    IonBackButton,
  ],
  templateUrl: 'reset-password.html',
  styleUrls: ['reset-password.scss'],
})
export class ResetPasswordPage implements OnInit {
  userData: ResetPasswordModel = new ResetPasswordModel({});
  key: string;
  token: string;
  isDesktop = this.platform.is('desktop');

  formGroup = this.fb.nonNullable.group(
    {
      Password: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ['', [Validators.required]],
    },
    {
      validators: [MatchPassword('Password', 'ConfirmPassword')],
    }
  );

  constructor(
    private accountService: AccountService,
    public platform: Platform,
    private loader: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.key = params['key'];
      this.token = params['token'];

      if (!this.key || !this.token) {
        this.router.navigateByUrl(AppRoutes.myCigars());
      }
    });
  }
  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.loader.show();

    this.userData.Key = this.key;
    this.userData.Token = this.token;
    this.userData.Password = this.formGroup.get('Password').value;
    this.userData.ConfirmPassword = this.formGroup.get('ConfirmPassword').value;
    this.accountService.resetPassword(this.userData).subscribe(
      () => {
        this.loader.hide();
        this.router.navigate(['login']);
      },
      (err) => {
        this.loader.hide();
      }
    );
  }
}
