import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { environment } from '@environments/environment';
import debug from 'debug';

import { ActiveUserService } from './active-user.service';
import { DeviceService } from './device.service';
import { Platform } from '@ionic/angular/standalone';

declare var APP_VERSION: string;

const log = debug('AppErrorHandler');

const filterMessageList = [
  "Messaging: This browser doesn't support the API's required to use the firebase SDK. (messaging/unsupported-browser).",
];

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
  private lastError;
  device: DeviceInfo;

  constructor(
    private activeUserService: ActiveUserService,
    private deviceService: DeviceService,
    private http: HttpClient,
    private platformService: Platform
  ) {
    Device.getInfo().then((device) => (this.device = device));
  }

  private _preventThrottling(err) {
    return (
      !this.lastError ||
      (this.lastError.err !== err && Date.now() - this.lastError.date > 30000)
    ); // avoid sending duplicate errors in short time
  }

  async handleError(err: Error) {
    if (!err?.message || filterMessageList.includes(err.message)) {
      log('skipping error', err);
      return;
    }

    // err.name;
    const message = {
      Message: JSON.stringify({
        message: err.message,
        url: document.location.href,
      }),
      StackTrace: err.stack,
      Platform: this.platformService.platforms().toString(),
      UUID: this.deviceService.getDeviceID(),
      UserId: this.activeUserService.getID(),
      DeviceManufacturer: this.device && this.device.manufacturer,
      DeviceModel: this.device && this.device.model,
      Name: 'CSWeb',
      Version: APP_VERSION,
    };

    if (environment.errorLog && this._preventThrottling(err)) {
      // handling error when app can't write to local database
      if (err.message && err.message.includes('QuotaExceededError')) {
        alert(
          'We noticed a problem with processing data. It is recommended that you restart your browser or the application.'
        );
      }

      this.lastError = {
        err: err,
        date: Date.now(),
      };

      this.http.post('errors', message).subscribe();
    }
    log('AppErrorHandler', message);
    throw err;
  }
}
