import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IState } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocialService } from '@services/social.service';
import * as UsersSearchActions from '@store/actions/users-search';
import { getErrorMessage } from '@utils/errors';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import * as UsersSearchSelectors from '../selectors/users-search';

@Injectable()
export class UsersSearchEffects {
  constructor(
    private readonly actions$: Actions<UsersSearchActions.ActionsUnion>,
    private socialService: SocialService,
    private store: Store<IState>
  ) {}

  reload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersSearchActions.UsersSearchUpdateTermAction),
      mergeMap(() => {
        return of(UsersSearchActions.UsersSearchRequestAction({ skip: 0 }));
      })
    )
  );

  requestAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersSearchActions.UsersSearchRequestAction),
      withLatestFrom(
        this.store.pipe(select(UsersSearchSelectors.selectCount)),
        this.store.pipe(select(UsersSearchSelectors.selectTerm))
      ),
      switchMap(([{ skip, take }, linesCount, term]) => {
        if (term === undefined || term.trim() === '') {
          return of(
            UsersSearchActions.UsersSearchSuccessAction({
              users: [],
              skip,
              take,
              total: 0,
            })
          );
        }
        return this.socialService
          .searchUsers(
            term.trim(),
            skip === undefined ? linesCount : skip,
            take
          )
          .pipe(
            map((response) =>
              UsersSearchActions.UsersSearchSuccessAction({
                users: response.body,
                skip,
                take,
                total: Number(response.headers.get('X-Total-Count') ?? '0'),
              })
            ),
            catchError((er: HttpErrorResponse) => {
              return of(
                UsersSearchActions.UsersSearchErrorAction({
                  error: getErrorMessage(er),
                })
              );
            })
          );
      })
    )
  );
}
