import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular/standalone';
import { KioskUserModel } from '@shared/models/kiosk-user.model';
import { KioskModel } from '@shared/models/kiosk.model';
import { MicrostoreResource } from '@shared/resources/microstore-resource';
import { ActiveUserService } from '@shared/services/active-user.service';
import { LoaderService } from '@shared/services/loader.service';

@Component({
    selector: 'microstore-settings',
    templateUrl: './microstore-settings.html',
    styleUrls: ['./microstore-settings.scss'],
})
export class MicrostoreSettingsPage implements OnInit {
    pageTitle: string;
    kiosks: KioskModel[];
    kiosk: KioskModel;

    constructor(
        private activatedRoute: ActivatedRoute,
        private loader: LoaderService,
        private alertCtrl: AlertController,
        private microstoreResource: MicrostoreResource,
        private activeUserService: ActiveUserService
    ) { }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data: any) => {
            this.pageTitle = data.title;
            this.kiosk = this.microstoreResource.getActiveKiosk();
            this.kiosks = data.kiosks;
        });
    }

    setActiveKiosk(kiosk: KioskModel) {
        this.microstoreResource.setActiveKiosk(kiosk.Id);
    }

    updateStore(form: NgForm) {
        if (!form.valid) {
            return;
        }
        this.loader.show();
        this.microstoreResource.updateKioskSettings(this.kiosk).subscribe(
            () => {
                this.loader.hide();
                form.form.markAsPristine();
            },
            (error) => {
                this.loader.hide();
                this.alertCtrl
                    .create({
                        header: 'Error occurred',
                        subHeader: error.message,
                        buttons: ['OK'],
                    })
                    .then((alert) => {
                        alert.present();
                    });
            }
        );
    }

    updateUser(form: NgForm, user: KioskUserModel) {
        if (!form.valid) {
            return;
        }

        this.loader.show();
        this.microstoreResource.updateUser(user).subscribe(
            () => {
                this.loader.hide();
                form.form.markAsPristine();
            },
            (error) => {
                this.loader.hide();
                this.alertCtrl
                    .create({
                        header: 'Error occurred',
                        subHeader: error.message,
                        buttons: ['OK'],
                    })
                    .then((alert) => {
                        alert.present();
                    });
            }
        );
    }

    removeUserAlert(user: KioskUserModel) {
        this.alertCtrl
            .create({
                header: 'Delete user',
                message: 'Are you sure you want to delete this user?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => { },
                    },
                    {
                        text: 'Delete',
                        handler: () => this.deleteUser(user),
                    },
                ],
            })
            .then((alert) => {
                alert.present();
            });
    }

    deleteUser(user: KioskUserModel) {
        this.loader.show();
        this.microstoreResource.deleteUser(user).subscribe(
            () => {
                this.loader.hide();
            },
            (error) => {
                this.loader.hide();
                this.alertCtrl
                    .create({
                        header: 'Error occurred',
                        subHeader: error.message,
                        buttons: ['OK'],
                    })
                    .then((alert) => {
                        alert.present();
                    });
            }
        );
    }
}
