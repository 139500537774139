import { Injectable } from '@angular/core';
import { IHumidorLite, IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as myHumidorsActions from '@store/actions/humidors';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class HumidorSocketEventHandler implements SocketEventHandlerInterface {
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<IHumidorLite>) {
    const humidor = socketMessage.Data;

    if (!humidor.Id) {
      return;
    }

    this.store.dispatch(
      myHumidorsActions.HumidorUpdateSuccess({
        humidor,
      })
    );
  }

  deleted(socketMessage: ISocketParsedMessage<string>) {
    const humidorId = socketMessage.Data;

    if (!humidorId || typeof humidorId !== 'string') {
      return;
    }
    this.store.dispatch(
      myHumidorsActions.HumidorDeleteSuccess({
        humidorId,
      })
    );
  }

  updated(socketMessage: ISocketParsedMessage<IHumidorLite>) {
    const humidor = socketMessage.Data;

    if (!humidor.Id) {
      return;
    }

    this.store.dispatch(
      myHumidorsActions.HumidorUpdateSuccess({
        humidor,
      })
    );
  }
}
