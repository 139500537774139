import { Injectable } from '@angular/core';
import { IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as socialActions from '@store/actions/social';

import { ISocialPostSocketModel } from '../../models/social-post.model';
import { ActiveUserService } from './../active-user.service';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class SocialPostSocketEventHandler
  implements SocketEventHandlerInterface
{
  constructor(
    private activeUserService: ActiveUserService,
    private store: Store<IState>
  ) {}

  created(socketMessage: ISocketParsedMessage<ISocialPostSocketModel>) {
    const post = socketMessage.Data;
    const userId = this.activeUserService.getID();

    if (post.FollowerIds && userId && post.FollowerIds.includes(userId)) {
      post.User.Followed = true;
    }
    this.store.dispatch(
      socialActions.SocialPostCreateSuccess({
        post: removeCurrentUserFlags(post),
      })
    );
  }

  deleted(socketMessage: ISocketParsedMessage<number>) {
    this.store.dispatch(
      socialActions.SocialPostRemoveSuccess({ postId: socketMessage.Data })
    );
  }

  updated(socketMessage: ISocketParsedMessage<ISocialPostSocketModel>) {
    const post = socketMessage.Data;
    const userId = this.activeUserService.getID();
    if (post.FollowerIds && userId && post.FollowerIds.includes(userId)) {
      post.User.Followed = true;
    }

    this.store.dispatch(
      socialActions.SocialPostEditSuccess({
        postId: socketMessage.Data.Id,
        post: removeCurrentUserFlags(post),
      })
    );
  }
}

function removeCurrentUserFlags(post: ISocialPostSocketModel) {
  const { Liked, ...rest } = post;
  return {
    ...rest,
  };
}
