import { AlertController, Platform } from '@ionic/angular/standalone';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import debug from 'debug';

import { AnalyticsService } from './analytics.service';
import { filter, map } from 'rxjs';

declare var APP_VERSION: string;

const log = debug('PWAService');

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class PWAService {
  constructor(
    private updates: SwUpdate,
    private platform: Platform,
    private alertController: AlertController,
    private analyticsService: AnalyticsService
  ) {}

  init() {
    const isNative = this.platform.is('capacitor');
    log('isNative', isNative);
    log('isEnabled', this.updates.isEnabled);
    if (isNative || !this.updates.isEnabled) return;

    this.checkForUpdate();
    this.trackOpeningMode();
  }

  async checkForUpdate() {
    log('checkForUpdate');
    const update = await this.updates.checkForUpdate();
    if (!update) return;
    const alert = await this.alertController.create({
      header: 'New version',
      message: 'A new version of CigarScanner is available!',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Refresh',
          handler: () => {
            this.analyticsService.upgradePWA(APP_VERSION);
            this.updates
              .activateUpdate()
              .then(() => document.location.reload());
          },
        },
      ],
    });

    await alert.present();
  }

  trackOpeningMode() {
    window.addEventListener('DOMContentLoaded', () => {
      let displayMode = 'browser tab';
      if (navigator['standalone']) {
        displayMode = 'standalone-ios';
      }
      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone';
      }
      // Log launch display mode to analytics
      log('launch', displayMode);
      this.analyticsService.openingMode(displayMode);
    });
  }
}
