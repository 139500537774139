import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IState } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as CigarsSearchActions from '@store/actions/cigars-search';
import { getCigarCreatedId } from '@utils/cigar';
import { getErrorMessage } from '@utils/errors';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { CigarsSearchService } from '../../services/cigars-search.service';
import * as CigarsSearchSelectors from '../selectors/cigars-search';

@Injectable()
export class CigarsSearchEffects {
  constructor(
    private readonly actions$: Actions<CigarsSearchActions.ActionsUnion>,
    private cigarsSearchService: CigarsSearchService,
    private store: Store<IState>
  ) {}

  reload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          CigarsSearchActions.CigarsSearchUpdateTermAction,
          CigarsSearchActions.CigarsSearchUpdateFiltersAction,
          CigarsSearchActions.CigarsSearchUpdateListAction,
        ]
      ),
      mergeMap(() => {
        return of(CigarsSearchActions.CigarsSearchRequestAction({ skip: 0 }));
      })
    )
  );

  requestAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CigarsSearchActions.CigarsSearchRequestAction),
      withLatestFrom(
        this.store.pipe(select(CigarsSearchSelectors.selectCount)),
        this.store.pipe(select(CigarsSearchSelectors.selectList)),
        this.store.pipe(select(CigarsSearchSelectors.selectTerm)),
        this.store.pipe(select(CigarsSearchSelectors.selectFilters))
      ),
      switchMap(([{ skip, take }, linesCount, list, term, filters]) => {
        if ((term === undefined || term.trim() === '') && filters === null) {
          return of(
            CigarsSearchActions.CigarsSearchSuccessAction({
              cigars: [],
              skip,
              take,
              total: 0,
            })
          );
        }
        return this.cigarsSearchService
          .getList(
            {
              ...filters,
              Search: term.trim(),
              InLists: list === 'lists',
              InHumidors: list === 'humidors',
            },
            skip === undefined ? linesCount : skip,
            take
          )
          .pipe(
            map((response) =>
              CigarsSearchActions.CigarsSearchSuccessAction({
                cigars: response.body.map((cigar) => ({
                  GeneratedId: getCigarCreatedId(cigar),
                  RatingSummary: cigar.Rating,
                  ...cigar,
                })),
                skip,
                take,
                total: Number(response.headers.get('X-Total-Count') ?? '0'),
              })
            ),
            catchError((er: HttpErrorResponse) => {
              return of(
                CigarsSearchActions.CigarsSearchErrorAction({
                  error: getErrorMessage(er),
                })
              );
            })
          );
      })
    )
  );
}
