import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SocialService } from '@services/social.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileResolver {
  constructor(private socialService: SocialService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.socialService.getUser(
      route.params['Id'],
      route.params['userType']
    );
  }
}
