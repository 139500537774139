import { Injectable } from '@angular/core';
import { IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as myHumidorsActions from '@store/actions/humidors';

import { SocketMessageModel } from '../../models/socket-message.model';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';

interface Data {
  Id: number;
  HumidorId: string;
  Humidity: number;
  Temperature: number;
  SensorDeviceId: string;
  Date: string;
  GatewayClientId: string;
  BatteryLevel: number;
  BluetoothSignal: unknown;
  ValuesRepeatedDate: string;
}

@Injectable()
export class SensorSocketEventHandler implements SocketEventHandlerInterface {
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<Data>) {
    // Ignore the message if HumidorId is not defined
    if (!socketMessage.Data.HumidorId) {
      return;
    }

    const {
      Humidity,
      Temperature,
      SensorDeviceId,
      GatewayClientId,
      BatteryLevel,
      ValuesRepeatedDate,
    } = socketMessage.Data;

    this.store.dispatch(
      myHumidorsActions.HumidorSocketOfflineUpdate({
        humidor: {
          Id: socketMessage.Data.HumidorId,
          Humidity,
          Temperature,
          SensorDeviceId,
          GatewayClientId,
          SensorBatteryLevel: BatteryLevel,
          ValuesRepeatedDate,
        },
      })
    );
  }

  deleted() {}

  updated() {}
}
