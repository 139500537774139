import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environments/environment';
import { ArticlesEnum } from '@models/articles';
import { AuthGuard } from '@shared/guards/auth.guard';
import { GuestGuard } from '@shared/guards/guest.guard';
import { SettingsGuard } from '@shared/guards/settings.guard';
import { SuggestAccountGuard } from '@shared/guards/suggest-account.guard';
import { AttributesResolver } from '@shared/resolvers/attributes.resolver';
import { DocumentPresenterResolver } from '@shared/resolvers/document-presenter.resolver';
import { KiosksResolver } from '@shared/resolvers/kiosks.resolver';
import { ListCigarLogResolver } from '@shared/resolvers/list-cigar-log.resolver';
import { PastOrdersResolver } from '@shared/resolvers/past-orders.resolver';
import { PendingOrdersResolver } from '@shared/resolvers/pending-orders.resolver';
import { ProductLineInfoResolver } from '@shared/resolvers/product-line-info.resolver';
import { UserProfileResolver } from '@shared/resolvers/user-profile.resolver';
import { AppRoutes } from '@utils/routes';

import { LoginPage } from './components/auth/login/login';
import { ResetPasswordPage } from './components/auth/reset-password/reset-password';
import { CustomCigarPage } from './components/custom-cigar/custom-cigar';
import { DocumentPresenter } from './components/document-presenter/document-presenter';
import { MicrostorePage } from './components/microstore/microstore';
import { MicrostoreSettingsPage } from './components/microstore/microstore-settings/microstore-settings';
import { OrderDetails } from './components/microstore/order-details/order-details';
import { PastOrdersPage } from './components/microstore/past-orders/past-orders';
import { PendingOrdersPage } from './components/microstore/pending-orders/pending-orders';
import { MyProfilePage } from './components/my-profile/my-profile';
import { SettingsPage } from './components/settings/settings';
import { CigarDetailsPage } from './pages/cigar-details/cigar-details';
import { TabsPage } from './pages/tabs-page/tabs-page';
import { AboutPage } from './pages/tools/about/about';
import { PlacesPage } from './pages/tools/places/places';

const cigarChildren = [
  {
    path: '',
    component: CigarDetailsPage,
  },
  {
    path: 'shape/:CigarCreatedId',
    children: [
      {
        path: '',
        component: CigarDetailsPage,
      },
    ],
  },
];

const socialPageChildRoutes = [
  {
    path: '',
    loadComponent: () => import('@pages/social/feed'),
  },
  {
    path: 'post/:Id',
    loadComponent: () => import('@pages/social/comments'),
  },
  {
    path: 'cigar/:CigarCreatedId',
    children: cigarChildren,
  },
  {
    path: ':userType/:Id',
    resolve: { UserProfile: UserProfileResolver },
    // runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@components/social/user-profile/user-profile'),
      },
      {
        path: 'followers',
        loadComponent: () => import('@components/social/user-follows'),
        data: { title: 'Followers' },
      },
      {
        path: 'following',
        loadComponent: () => import('@components/social/user-follows'),
        data: { title: 'Following' },
      },
      {
        path: 'reviews',
        loadComponent: () => import('@pages/user-reviews/user-reviews'),
      },
      {
        path: 'posts',
        loadComponent: () => import('@components/social/user-posts'),
      },
      {
        path: 'humidors',
        loadComponent: () => import('@components/social/user-humidors'),
      },
      {
        path: 'list/:List',
        loadComponent: () => import('@components/social/user-cigar-list'),
      },
    ],
  },
];

const microstoreChildRoutes = [
  {
    path: 'pending-orders',
    children: [
      {
        path: '',
        component: PendingOrdersPage,
        resolve: { orders: PendingOrdersResolver },
      },
    ],
  },
  {
    path: 'past-orders',
    children: [
      {
        path: '',
        component: PastOrdersPage,
        resolve: { orders: PastOrdersResolver },
      },
    ],
  },
  {
    path: 'settings',
    resolve: { kiosks: KiosksResolver },
    component: MicrostoreSettingsPage,
  },
  {
    path: '',
    component: PendingOrdersPage,
    resolve: { orders: PendingOrdersResolver },
    children: [
      {
        path: ':Id',
        component: OrderDetails,
      },
    ],
  },
];

const MyCigarsPages = {
  path: 'my-cigars',
  children: [
    {
      path: '',
      loadComponent: () => import('@pages/my-cigars/my-cigars'),
    },
    {
      path: ':List/:CigarLogId',
      // resolve: { cigarLog: ListCigarLogResolver },
      children: cigarChildren,
    },
  ],
};

const MySocialPages = [
  {
    path: 'social',
    data: { title: 'Social' },
    children: socialPageChildRoutes,
  },
];

const routes: Routes = [
  ...environment.routes,
  {
    path: '',
    canActivateChild: [SuggestAccountGuard],
    children: [
      { path: 'my-humidors', redirectTo: AppRoutes.myHumidors() },
      { path: 'my-cigars', redirectTo: AppRoutes.myCigars() },
      { path: 'tools', redirectTo: AppRoutes.tools() },
      { path: 'social', redirectTo: AppRoutes.social() },
      {
        path: 'tabs',
        component: TabsPage,
        children: [
          MyCigarsPages,
          ...MySocialPages,
          {
            path: 'tools',
            loadChildren: () => import('@pages/tools/tools.module'),
          },
          {
            path: 'my-humidors',
            loadChildren: () => import('@pages/my-humidors/my-humidors.module'),
          },
        ],
      },
      {
        path: 'login',
        canActivate: [GuestGuard],
        component: LoginPage,
        data: { title: 'Login' },
      },
      {
        path: 'reset-password',
        component: ResetPasswordPage,
        data: { title: 'Reset Password' },
      },
      {
        path: 'microstore',
        component: MicrostorePage,
        data: { title: 'My Cigar MicroStore' },
        children: microstoreChildRoutes,
      },
      // {
      //   path: 'my-profile2',
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./pages/connected-user/connected-user.module'),
      // },
      {
        path: 'my-profile',
        canActivate: [AuthGuard],
        component: MyProfilePage,
        data: { title: 'My Profile' },
      },
      {
        path: 'settings',
        canDeactivate: [SettingsGuard],
        component: SettingsPage,
        data: { title: 'Settings' },
      },
      {
        path: 'privacy-policy',
        component: DocumentPresenter,
        data: {
          title: 'Privacy Policy',
          articleId: ArticlesEnum.PRIVACY_POLICY,
        },
        resolve: { documentData: DocumentPresenterResolver },
      },
      {
        path: 'eula',
        component: DocumentPresenter,
        data: { title: 'EULA', articleId: ArticlesEnum.EULA },
        resolve: { documentData: DocumentPresenterResolver },
      },
      {
        path: 'voice-assistant',
        component: DocumentPresenter,
        data: {
          title: 'Voice Assistant',
          articleId: ArticlesEnum.VOICE_ASSISTANT,
        },
        resolve: { documentData: DocumentPresenterResolver },
      },
      { path: 'about', component: AboutPage, data: { title: 'About' } },
      {
        path: 'cigar/:CigarCreatedId',
        children: cigarChildren,
      },
      {
        path: 'custom-cigar/brand-search',
        loadComponent: () => import('@pages/line-search'),
      },
      {
        path: 'custom-cigar/add',
        component: CustomCigarPage,
        data: { title: 'Add Your Cigar' },
        resolve: {
          attributes: AttributesResolver,
          lineInfo: ProductLineInfoResolver,
        },
      },
      {
        path: 'custom-cigar/edit',
        component: CustomCigarPage,
        data: { title: 'Edit Your Cigar' },
        resolve: {
          cigarlog: ListCigarLogResolver,
          attributes: AttributesResolver,
        },
      },
      {
        path: 'notifications',
        loadComponent: () => import('@pages/notifications'),
      },
      {
        path: 'feedback',
        loadComponent: () => import('@pages/feedback'),
      },
      {
        path: '**',
        redirectTo: AppRoutes.myCigars(),
      },
    ],
  },
  {
    path: '**',
    redirectTo: AppRoutes.myCigars(),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // not sure yet if using PreloadAllModules is a good idea.
      // https://www.telerik.com/blogs/preloading-routes-angular-faster-navigation
      // preloadingStrategy: PreloadAllModules,
      // enableTracing: !environment.production,
    }),
    CigarDetailsPage,
    PlacesPage,
  ], // removed relativeLinkResolution: 'legacy'
  exports: [RouterModule],
})
export class AppRoutingModule {}
