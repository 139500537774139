import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var ThemeDetection = /** @class */function (_super) {
  __extends(ThemeDetection, _super);
  function ThemeDetection() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  ThemeDetection.prototype.isAvailable = function () {
    return cordova(this, "isAvailable", {}, arguments);
  };
  ThemeDetection.prototype.isDarkModeEnabled = function () {
    return cordova(this, "isDarkModeEnabled", {}, arguments);
  };
  ThemeDetection.ɵfac = /* @__PURE__ */(() => {
    let ɵThemeDetection_BaseFactory;
    return function ThemeDetection_Factory(t) {
      return (ɵThemeDetection_BaseFactory || (ɵThemeDetection_BaseFactory = i0.ɵɵgetInheritedFactory(ThemeDetection)))(t || ThemeDetection);
    };
  })();
  ThemeDetection.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ThemeDetection,
    factory: ThemeDetection.ɵfac
  });
  ThemeDetection.pluginName = "ThemeDetection";
  ThemeDetection.plugin = "cordova-plugin-theme-detection";
  ThemeDetection.pluginRef = "cordova.plugins.ThemeDetection";
  ThemeDetection.repo = "https://github.com/mariusbackes/cordova-plugin-theme-detection";
  ThemeDetection.install = "cordova plugin add cordova-plugin-theme-detection";
  ThemeDetection.installVariables = [];
  ThemeDetection.platforms = ["iOS", "Android"];
  ThemeDetection = __decorate([], ThemeDetection);
  return ThemeDetection;
}(AwesomeCordovaNativePlugin);
export { ThemeDetection };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
