<ion-item class="order-wrapper" (click)="openModal()">
  <ion-label class="ion-text-wrap">
    <ion-text color="primary">
      <h3>Shopping Cart #<strong>{{ order.Id }}</strong></h3>
    </ion-text>
    <ion-text
      ><p>
        {{ order.TotalQuantity }} Items - {{ order.TotalCost | currency }}
        <span *ngIf="order.Comment"> - {{ order.Comment }}</span>
      </p>
    </ion-text>
    <ion-text
      ><p>Customer Name: <strong>{{ order.BuyerName }}</strong></p>
    </ion-text>
  </ion-label>
  <ion-note slot="end"
    ><p>{{ order.CreatedOn | date:'mediumDate' }}</p>

    <p>{{ order.CreatedOn | date:'shortTime' }}</p>
  </ion-note>
</ion-item>
