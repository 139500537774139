import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { SettingsPage } from '@components/settings/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard  {
  constructor() {}

  async canDeactivate(component: SettingsPage) {
    return component ? component.canDeactivate() : Promise.resolve(true);
  }
}
