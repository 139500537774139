import { Injectable, NgZone } from '@angular/core';

import { SocketMessageModel } from '../../models/socket-message.model';
import { EmitterService } from '../emitter.service';
import { BaseSocketEventHandler } from './base.socket-event-handler';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class GatewayResponseSocketEventHandler extends BaseSocketEventHandler implements SocketEventHandlerInterface {

  constructor(
    private emitterService: EmitterService,
    protected zone: NgZone
  ) {
    super(zone);
  }

  created(socketMessage: SocketMessageModel) {
    this.emitterService.gatewayResponseReceived();
  }

  deleted(socketMessage: SocketMessageModel) { }

  updated(socketMessage: SocketMessageModel) { }
}
