<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/login"></ion-back-button>
    </ion-buttons>
    <ion-title>Reset Password</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Reset Password</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="page-container">
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <ion-list class="pt-0" [inset]="true" lines="full">
        <div>
          <div class="logo-holder">
            <div class="logo"></div>
          </div>
        </div>
        <ion-item *rxLet="formGroup.get('Password'); let control">
          <ion-input
            label="Password *"
            labelPlacement="floating"
            type="password"
            autocomplete="new-password"
            [formControl]="control"
            [errorText]="
              control.touched
                ? control.hasError('required')
                  ? 'Field is required'
                  : control.hasError('minlength')
                  ? 'Minimum 6 characters'
                  : ''
                : ''
            "
          >
          </ion-input>
        </ion-item>

        <ion-item *rxLet="formGroup.get('ConfirmPassword'); let control">
          <ion-input
            label="Confirm Password *"
            labelPlacement="floating"
            type="password"
            autocomplete="new-password"
            [formControl]="control"
            [errorText]="
              control.touched
                ? control.hasError('required')
                  ? 'Field is required'
                  : control.hasError('passwordMismatch')
                  ? 'Passwords do not match'
                  : ''
                : ''
            "
          ></ion-input>
        </ion-item>
      </ion-list>
      <div
        *ngIf="isDesktop"
        class="flex flex-row-reverse ion-padding-horizontal"
      >
        <ion-button size="large" type="submit"> Submit </ion-button>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer *ngIf="!isDesktop">
  <ion-button
    expand="full"
    size="large"
    color="primary"
    type="button"
    (click)="submit()"
  >
    Submit
  </ion-button>
</ion-footer>
