import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { KioskCartModel } from '@shared/models/kiosk-cart.model';

import { OrderDetails } from './../order-details/order-details';

@Component({
    selector: 'order',
    templateUrl: './order.html',
})
export class Order implements OnInit {
    @Input() order: KioskCartModel;

    constructor(private modalService: ModalService) { }

    ngOnInit() { }

    async openModal() {
        const modal = await this.modalService.createCSModal(OrderDetails, {
            order: this.order,
        });
        await modal.present();
    }
}
