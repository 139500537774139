import { Injectable } from '@angular/core';
import { IHumidorBase, IHumidorLite, IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as myHumidorsActions from '@store/actions/humidors';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class HumidorInfoSocketEventHandler
  implements SocketEventHandlerInterface
{
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<IHumidorLite>) {}

  deleted(socketMessage: ISocketParsedMessage<string>) {}

  updated(socketMessage: ISocketParsedMessage<IHumidorLite>) {
    const humidor = socketMessage.Data as IHumidorBase;

    // Ignore the message if Id is not defined
    if (!humidor.Id) {
      return;
    }

    this.store.dispatch(
      myHumidorsActions.HumidorUpdateSuccess({
        humidor,
      })
    );
  }
}
