<ion-header>
  <ion-toolbar>
    <ion-title>Order #{{ order.Id }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="page-container details-wrapper">
    <div class="order-info">
      <div>
        <div class="order-number">
          Order #<strong>{{ order.Id }}</strong>
        </div>
        <div *ngIf="!order.StatusCode">
          Order Date: {{ order.CreatedOn | date : 'medium' }}
        </div>
        <div *ngIf="order.StatusCode">
          Validation Date: {{ order.CreatedOn | date : 'medium' }}
        </div>
      </div>
      <div>
        <div class="name">{{ order.BuyerName }}</div>
        <button *ngIf="!order.StatusCode" (click)="approveOrder()">
          Validate Order
        </button>
      </div>
    </div>

    <div class="heading">Details</div>
    <div class="product" *ngFor="let product of order.Items">
      <span>{{ product.ProductName }}</span>
      <div>
        <span class="quantity">{{ product.Quantity }}</span>
        <span class="price"> {{ product.TotalPrice | currency }}</span>
      </div>
    </div>

    <div class="payment-info">
      <div>TOTAL: {{ order.TotalCost | currency }}</div>
    </div>
  </div>
</ion-content>
