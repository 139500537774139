import { Injectable } from '@angular/core';
import { ICigarLog, IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as myCigarsActions from '@store/actions/my-cigars';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class CigarLogSocketEventHandler implements SocketEventHandlerInterface {
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<ICigarLog>) {
    const cigarLog = socketMessage.Data as ICigarLog;

    // Ignore the message if Id is not defined
    if (!cigarLog.Id) {
      return;
    }

    this.store.dispatch(
      myCigarsActions.MyCigarsCreateSuccessAction({
        listType: cigarLog.List,
        cigarLog,
      })
    );
  }

  deleted(socketMessage: ISocketParsedMessage<number>) {
    const cigarLogId = socketMessage.Data;

    this.store.dispatch(
      myCigarsActions.MyCigarsDeleteFromSocket({
        cigarLogId,
      })
    );
  }

  updated(socketMessage: ISocketParsedMessage<ICigarLog>) {
    const cigarLog = socketMessage.Data;

    // Ignore the message if Id is not defined
    if (!cigarLog.Id) {
      return;
    }

    this.store.dispatch(
      myCigarsActions.MyCigarsUpdateSuccessAction({
        cigarLog,
      })
    );
  }
}
