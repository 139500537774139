import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { MicrostoreResource } from '../resources/microstore-resource';

@Injectable({
  providedIn: 'root'
})
export class KiosksResolver  {

  constructor(
    private microstoreResource: MicrostoreResource
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.microstoreResource.getKiosks();
  }
}
