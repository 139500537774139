<!-- environmentInjector is to be able to lazy load standalone component as pages -->
<ion-tabs>
  <ion-tab-bar *ngIf="isMobile" slot="bottom" color="primary">
    <ion-tab-button tab="my-cigars" aria-label="my cigars">
      <ion-icon src="assets/images/my-cigars.svg"></ion-icon>
      <ion-label>My Cigars</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="social" aria-label="social page">
      <ion-icon name="share-social"></ion-icon>
      <ion-label>Social</ion-label>
    </ion-tab-button>

    <ion-tab-button class="camera" color="primary" (click)="addFromCamera()">
      <ion-icon size="large" name="camera"></ion-icon>
    </ion-tab-button>

    <ion-tab-button tab="my-humidors" aria-label="my humidors">
      <ion-icon src="assets/images/my-humidors.svg"></ion-icon>
      <ion-label>My humidors</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="tools" aria-label="tools">
      <ion-icon name="hammer"></ion-icon>
      <ion-label>Tools</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
