import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map, first, catchError } from 'rxjs/operators';

import { AccountService } from '../services/account.service';
import { ActiveUserService } from '../services/active-user.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SuggestAccountGuard {
  firstLoad = true;

  constructor(
    private router: Router,
    private activeUserService: ActiveUserService,
    private accountService: AccountService
  ) {}

  canActivateChild(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (routeSnapshot.routeConfig.path === 'reset-password') {
      // we don't want suggestions if the user open reset-password page
      // without this we would be redirected to login page...
      return true;
    }
    if (!this.firstLoad || this.activeUserService.isAuthenticated()) {
      this.firstLoad = false;
      return true;
    } else {
      this.firstLoad = false;
      return this.accountService.suggestAccount().pipe(
        map((suggestedAccount) => {
          if (!suggestedAccount) {
            return true;
          }

          this.router.navigate(['login'], {
            queryParams: {
              email:
                suggestedAccount.IsCigarScanner || suggestedAccount.IsNeptune
                  ? suggestedAccount.Email
                  : null,
              isNeptune: suggestedAccount.IsNeptune,
              isFacebook: suggestedAccount.IsFacebook,
            },
          });

          return false;
        }),
        catchError((err) => {
          // avoid blank screen when opening app offline
          return of(true);
        })
      );
    }
  }
}
