import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'microstore',
    templateUrl: 'microstore.html',
})
export class MicrostorePage implements OnInit, OnDestroy {
    routeSub: Subscription;
    selectedTab: 'pending-orders' | 'past-orders' | 'settings';

    constructor(private router: Router, private routerService: Router) { }

    ngOnInit() {
        this.routeSub = this.router.events.subscribe((event) => {
            // @Todo: remove this shit
            if (event instanceof NavigationEnd) {
                if (
                    event.url === '/microstore' ||
                    event.url.includes('/microstore/pending-orders')
                ) {
                    this.selectedTab = 'pending-orders';
                }

                if (event.url.includes('/microstore/past-orders')) {
                    this.selectedTab = 'past-orders';
                }

                if (event.url.includes('/microstore/settings')) {
                    this.selectedTab = 'settings';
                }
            }
        });
    }

    segmentButtonClicked(value) {
        this.routerService.navigate(['microstore', value]);
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
