<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/tabs/my-cigars"></ion-back-button>
    </ion-buttons>
    <ion-title>Login</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Login</ion-title>
    </ion-toolbar>
  </ion-header>
  <div class="logo-holder">
    <div class="logo"></div>
  </div>
  <cs-login
    [email]="email"
    [isNeptune]="isNeptune"
    [isFacebook]="isFacebook"
    (loginSuccess)="loginSuccess()"
    (registerSuccess)="loginSuccess()"
  ></cs-login>
</ion-content>

<ion-footer>
  <ion-button fill="clear" color="primary" size="small" routerLink="/eula">
    terms and conditions
  </ion-button>
</ion-footer>
