import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular/standalone';
import { IState } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HumidorsService } from '@services/humidor.service';
import { ToastService } from '@services/toast.service';
import * as humidorsActions from '@store/actions/humidors';
import { getErrorMessage } from '@utils/errors';
import { AppRoutes } from '@utils/routes';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class HumidorsEffects {
  constructor(
    private readonly actions$: Actions<humidorsActions.ActionsUnion>,
    private humidorsService: HumidorsService,
    private navController: NavController,
    private alertCtrl: AlertController,
    private store: Store<IState>,
    private toastService: ToastService
  ) {}

  requestAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(humidorsActions.HumidorsRequest),
      switchMap(() =>
        this.humidorsService.getHumidorsLite().pipe(
          map((response) =>
            humidorsActions.HumidorsSuccess({ humidors: response })
          ),
          catchError((er: HttpErrorResponse) =>
            of(humidorsActions.ErrorAction())
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(humidorsActions.HumidorCreateRequest),
      switchMap(({ name }) =>
        this.humidorsService.createHumidor(name).pipe(
          map((humidor) => {
            this.toastService.show('Created');
            this.navController.navigateRoot([AppRoutes.myHumidors()]);
            return humidorsActions.HumidorCreateSuccess({ humidor });
          }),
          catchError((er: HttpErrorResponse) =>
            of(humidorsActions.ErrorAction())
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(humidorsActions.HumidorUpdateRequest),
      switchMap(({ humidorId, humidor, changeOwner }) =>
        this.humidorsService
          .updateHumidor(humidorId, humidor, changeOwner)
          .pipe(
            map((humidor) => {
              this.toastService.show('Saved');
              this.navController.navigateRoot([AppRoutes.myHumidors()]);
              return humidorsActions.HumidorUpdateSuccess({ humidor });
            }),
            catchError((er: HttpErrorResponse) => {
              const message = getErrorMessage(er);

              if (
                message ===
                'This Sensor is already associated with another Humidor'
              ) {
                this.alertCtrl
                  .create({
                    message:
                      'This sensor is already paired with another humidor. Click Transfer to move this sensor to this new humidor or Cancel',
                    buttons: [
                      { text: 'CANCEL' },
                      {
                        text: 'TRANSFER',
                        handler: () => {
                          this.store.dispatch(
                            humidorsActions.HumidorUpdateRequest({
                              humidorId,
                              humidor,
                              changeOwner: true,
                            })
                          );
                        },
                      },
                    ],
                  })
                  .then((alert) => {
                    alert.present();
                  });
              }
              return of(humidorsActions.ErrorAction());
            })
          )
      )
    )
  );

  reorder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(humidorsActions.HumidorsReorderRequest),
      switchMap(({ ids }) =>
        this.humidorsService.reorderHumidors(ids).pipe(
          map((humidors) => {
            this.navController.navigateRoot([AppRoutes.myHumidors()]);
            return humidorsActions.HumidorsReorderSuccess({ humidors });
          }),
          catchError((er: HttpErrorResponse) =>
            of(humidorsActions.ErrorAction())
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(humidorsActions.HumidorDeleteRequest),
      switchMap(({ humidorId }) =>
        this.humidorsService.deleteHumidor(humidorId).pipe(
          map(() => {
            this.navController.navigateRoot([AppRoutes.myHumidors()]);
            return humidorsActions.HumidorDeleteSuccess({ humidorId });
          }),
          catchError((er: HttpErrorResponse) =>
            of(humidorsActions.ErrorAction())
          )
        )
      )
    )
  );
}
