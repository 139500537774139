import { Injectable } from '@angular/core';
import { ICigar, ICigarNote, IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as CigarsActions from '@store/actions/cigars';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

@Injectable()
export class ProductNoteSocketEventHandler
  implements SocketEventHandlerInterface
{
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<ICigarNote>) {
    this.upsert(socketMessage);
  }

  deleted(socketMessage: ISocketParsedMessage<number>) {
    // we don't have the cigar info in the socket message
    // so we can't delete it from the store
    // we'll just ignore it
  }

  updated(socketMessage: ISocketParsedMessage<ICigarNote>) {
    this.upsert(socketMessage);
  }

  private upsert(socketMessage: ISocketParsedMessage<ICigarNote>) {
    const cigar = {
      ProductId: socketMessage.Data.ProductId,
      LineId: socketMessage.Data.LineId,
    } as ICigar;

    this.store.dispatch(
      CigarsActions.CigarUpdateNoteSuccess({
        cigar,
        note: socketMessage.Data,
      })
    );
  }
}
